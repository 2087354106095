import React from 'react'
import UploadDustyForm from '../components/UploadDustyForm'

const UploadDustyPage = () => {
    return (
        <div>
            <UploadDustyForm />
        </div>
    )
}

export default UploadDustyPage
