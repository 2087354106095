import React, {useEffect, useState} from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles'

import dustyLogo from '../images/dusty-logo.png';
import LayersIcon from '@mui/icons-material/Layers';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import UploadIcon from '@mui/icons-material/Upload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import API from '../API'
import LayerDefPage from '../pages/LayerDefPage';

const Navigation = () => {
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const [loggedIn, setLoggedIn] = useState(false);
    const [userInfo, setUserInfo] = useState(null);
    const [profileModalOpen, setProfileModalOpen] = useState(false);
    const [pathName, setPathName] = useState(null)

    const handleLogout = () => {
        API.logout({ token: localStorage.getItem('access')})
            .then(res => {
                console.log('LOGGED OUT')
                localStorage.clear()
                setLoggedIn(false)
                setUserInfo(null)
                history.push('/')
            })
            .catch(err => {
                console.log(err)
                history.push('/')
            })
    }

    // window.addEventListener('scroll',()=> console.log(window.scrollY))
    
    useEffect(() => {
        const msInterval = 10000;
        
        // initial
        handleCheckTokens()

        const interval = setInterval(() => {
            refreshFunction()
        }, msInterval);
        
        return () => clearInterval(interval);
    }, [location])

    const refreshFunction = () => {
        handleCheckTokens()
    }

    const handleCheckTokens = () => {
        if (location.pathname === '/' || location.pathname === '/oauth2callback') {
            console.log('waiting for auth...')
        } else {
            setLoggedIn(true)
            API.verifyTokens({ token: localStorage.getItem('access'), refreshToken: localStorage.getItem('refresh')})
                .then(res => {
                    console.log('VALID AUTH,', res.data)
                    if (typeof res.data == 'object') {
                        localStorage.setItem('access', JSON.stringify(res.data))
                    }
                    if (userInfo != null) {
                        // console.log('userInfo exists')
                    } else {
                        API.getUserInfo({token: localStorage.getItem('access')})
                            .then(res => {
                                setUserInfo(res.data)
                            })
                            .catch(err => {
                                console.log('could not get userInfo')
                            })
                    }
                })
                .catch(err => {
                    console.log('INVALID AUTH TOKEN')
                    // localStorage.clear()
                    setLoggedIn(false)
                    setUserInfo(null)
                    history.push('/')
                    // handleLogout()
                })
            // Getting path name from url
            const params = location.pathname.split('/')
            const thePath = params[1]
            // console.log(thePath)
            if (thePath == 'reports') {
                setPathName('reports')
            }
            if (thePath == 'addJob') {
                setPathName('addJob')
            }
            if (thePath == 'layerDef') {
                setPathName('layerDef')
            }
            if (thePath == 'uploadDusty') {
                setPathName('uploadDusty')
            }
        }
    }

    // useEffect(() => {
    //     if (location.pathname === '/' || location.pathname === '/oauth2callback') {
    //         console.log('do not get tokens')
    //     } else {
    //         setLoggedIn(true)
    //         API.verifyTokens()
    //             .then(res => {
    //                 console.log('token verified')
    //             })
    //             .catch(err => {
    //                 // console.log(err)
    //                 console.log('token bad')
    //                 setLoggedIn(false)
    //                 setUserInfo(null)
    //                 history.push('/')
    //                 // handleLogout()
    //             })
    //     }

    // }, [location])

    return (
        <>
            <div className={classes.navContainer}>
                <div className={classes.titleContainer} onClick={() => history.push('/reports')}>
                    <img src={dustyLogo} className={classes.dustyLogo} alt="dusty-logo"/>
                    <Typography className={classes.title}>
                        Dusty Robotics | QA Reports
                    </Typography>
                </div>
                { loggedIn && (
                    <div className={classes.buttonContainer}>
                        {/* <p style={{fontSize: '100px'}}>hello</p> */}
                        <Link to="/uploadDusty">
                            <button className={classes.layerButton} variant="outlined" style={{backgroundColor: (pathName == 'uploadDusty') && '#ed7a1c'}}>
                                <CloudUploadIcon style={{fontSize: '18px'}}/>
                            </button>
                        </Link>
                        {/* <Link to="/layerDef">
                            <button className={classes.layerButton} variant="outlined" style={{backgroundColor: (pathName == 'layerDef') && '#ed7a1c'}}>
                                <LayersIcon style={{fontSize: '18px'}}/>
                            </button>
                        </Link> */}
                        {/* <Link to="/addJob">
                            <button className={classes.button} variant="outlined" style={{backgroundColor: (pathName == 'addJob') && '#ed7a1c'}}>
                                Add job
                            </button>
                        </Link> */}
                        <Link to="/reports">
                            <button className={classes.button} variant="outlined" style={{backgroundColor: (pathName == 'reports') && '#ed7a1c'}}>
                                Reports
                            </button>
                        </Link>
                        { userInfo ? (
                            <img key={userInfo.email} className={classes.profilePic} src={userInfo.picture} alt="profile pic" referrerPolicy="no-referrer" onClick={() => setProfileModalOpen(true)}/>
                        ) : (
                            <button className={classes.button} variant="outlined" onClick={() => handleLogout()}>
                                Log out
                            </button>
                        )}
                    </div>
                )}
            </div>
            { userInfo && (
                <div className={classes.modalDiv} style={{
                    display: profileModalOpen ? 'block' : 'none'
                }}>
                    <div
                        className={classes.modalBackdrop}
                        onClick={() => setProfileModalOpen(false)}
                        style={{
                            // backgroundColor: 'rgba(0,0,0,0.5)',
                            width: '100vw',
                            height: '100vh',
                            position: 'fixed',
                            top: 0,
                            zIndex: 3
                        }}
                    >
                    </div>
                    <div className={classes.profileModal}>
                        <img className={classes.modalPic} src={userInfo.picture} alt="profile pic" referrerPolicy="no-referrer"/>
                        <p className={classes.modalName}>{userInfo.name}</p>
                        <p className={classes.modalEmail}>{userInfo.email}</p>
                        <button onClick={() => handleLogout()} className={classes.modalLogout}>Log out</button>
                    </div>
                </div>
            )}
        </>
    )
}


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            // margin: theme.spacing(1),
        }
    },
    navContainer: {
        width: '100vw',
        // margin: 'auto',
        backgroundColor: '#f6862a',
        height: '55px',
        display: 'flex',
        justifyContent: 'space-between',
        
        marginBottom: '40px',
        position: 'fixed',
        zIndex: '2',
        boxShadow: window.scrollY == 0 ? '0 3px 8px -1px rgba(0,0,0,0.3)' : ""
        // boxShadow:  ? '0 3px 8px -1px rgba(0,0,0,0.3)' : ""
        // backgroundColor: window.scrollY == 0 ? 'red' : "blue"
    },
    dustyLogo: {
        height: '100%',
        marginLeft: '10px',
        '@media (max-width:321px)': {
            display: 'none',
        },
    },
    titleContainer: {
        display: 'flex',
        cursor: 'pointer',
        width: '60vw',
        maxWidth: '300px',
        '@media (max-width:590px)': {
            width: '15vw',
        },
        '@media (max-width:321px)': {
            width: '1vw',
        },
    },
    title: {
        // verticalAlign: 'middle',
        marginTop: '15px !important',
        marginLeft: '15px !important',
        color: 'white',
        // position: 'absolute',
        // top: '50%',
        '@media (max-width:600px)': {
            display: 'none',
        },
    },
    buttonContainer: {
        marginTop: '10px',
        // verticalAlign: 'middle',
        marginLeft: '15px',
        marginRight: '15px',
        flexWrap: 'nowrap',
        // display: 'inline-block',
        // backgroundColor: 'red',
    },
    button: {
        border: 'none',
        borderRadius: '7px',
        backgroundColor: 'transparent',
        color: 'white',
        padding: '10px',
        '&:hover': {
            backgroundColor: '#ed7a1c'
        },
    },
    layerButton: {
        border: 'none',
        borderRadius: '7px',
        backgroundColor: 'transparent',
        color: 'white',
        padding: '10px',
        paddingBottom: '4px',
        verticalAlign: 'middle',
        '&:hover': {
            backgroundColor: '#ed7a1c'
        },
    },
    profilePic: {
        height: '30px',
        verticalAlign: 'middle',
        borderRadius: '50px',
        marginLeft: '5px',
        cursor: 'pointer',
        '&:hover': {
            filter: 'drop-shadow(0 0 1px rgba(0,0,0,0.25))'
        },
    },
    modalDiv: {
        // display: profileModalOpen ? 'block' : 'none'
    },
    profileModal: {
        position: 'fixed',
        backgroundColor: 'white',
        // display: 'flex',
        // justifyContent: 'right',
        boxShadow: '0 0 5px rgba(0,0,0,0.25)',
        borderRadius: '5px',
        right: 10,
        top: 60,
        textAlign: 'center',
        maxWidth: '250px',
        minWidth: '250px',
        zIndex: '4',
        padding: '20px',
    },
    modalPic: {
        borderRadius: 100,
        height: '60px',
    },
    modalName: {
        fontSize: '25px !important',
        marginTop: '10px',
        marginBottom: '5px',
    },
    modalEmail: {
        fontSize: '15px !important',
        color: 'gray',
        marginTop: '0px',
        marginBottom: '10px',
    },
    modalLogout: {
        border: '1px solid #969696',
        borderRadius: '5px',
        padding: '5px',
        fontFamily: 'Roboto',
        backgroundColor: 'white',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#ebebeb'
        },
    },
}))

export default Navigation
