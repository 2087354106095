import React, { useState, useEffect } from 'react';
import { Select, MenuItem, Typography, TextField, Button, FormControl, CircularProgress, Modal, Backdrop, Fade, Box } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles'
import CustomHeader from './CustomHeader';
import CustomSubheader from './CustomSubheader';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import ListAltIcon from '@mui/icons-material/ListAlt';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

import API from '../API';
import moment from 'moment';

const initialState = [{ day: 1, robot: '', date: new Date(), startTime: '07:00:00', endTime: '16:00:00', notes: '' }]

const JobForm = () => {
    const classes = useStyles();
    const [ jobName, setJobName ] = useState('');
    const [ dustyFile, setDustyFile ] = useState('');
    const [ layerDefinition, setLayerDefinition ] = useState('');
    const [ formData, setFormData ] = useState(initialState);
    const [ currentIndex, setCurrentIndex ] = useState(0);

    const [ disableBack, setDisableBack ] = useState(true);
    const [ disableNext, setDisableNext ] = useState(true);
    const [ disableSubmit, setDisableSubmit ] = useState(true);

    const [loading, setLoading] = useState(false);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [openErrMod, setOpenErrMod] = useState(false);
    const handleOpenErrMod = () => setOpenErrMod(true);
    const handleCloseErrMod = () => setOpenErrMod(false);

    const handleChange = (index, key, value) => {
        // setFormData({ ...formData, [e.target.name]: e.target.value });

        let temp_state = [...formData]
        let temp_element = { ...temp_state[index]}
        temp_element[key] = value
        temp_state[index] = temp_element;
        setFormData(temp_state)

        console.log('jobName', jobName)
        console.log('dustyFile', dustyFile)
        console.log('layerDefinition', layerDefinition)
        console.log('formdata', formData)
    }

    const handleAddDay = () => {
        console.log('adding day..')
        const tomorrow = new Date(formData[currentIndex].date)
        tomorrow.setDate(tomorrow.getDate() + 1)
        formData.push({ day: formData.length+1, robot: formData[currentIndex].robot, date: tomorrow, startTime: formData[currentIndex].startTime, endTime: formData[currentIndex].endTime, notes: formData[currentIndex].notes })

        // setTimeout(() => {
            setCurrentIndex(formData.length-1)
        // }, 500);
    }

    const handleRemoveDay = () => {
        console.log('removing day..')
        
        // if deleting NOT first item
        setCurrentIndex(currentIndex-1)
        console.log(formData.splice(currentIndex, 1))
        setTimeout(() => {
        }, 1000);
        // console.log([...formData.slice(0,currentIndex+1), ...formData.slice(currentIndex+2)])
        // setFormData([...formData.slice(0,currentIndex+1), ...formData.slice(currentIndex+2)])
    }

    const handleSubmit = () => {
        // console.log([formData])
        const reg = /^[0-9A-Za-z\s\-]+$/
        if (!reg.test(jobName)) {
            alert('Job names can only include letters, numbers, spaces, and dashes.')
            return
        }

        for (let i=0; i<formData.length; i++) {
            // formData[i].robot = formData[i].robot.trim();
            console.log(formData[i].robot.split(','))
            let separatedRobots = formData[i].robot.split(',')
            for (let j=0; j<separatedRobots.length; j++) {
                console.log(separatedRobots[j])
                if (!separatedRobots[j].includes('amber')) {
                    // console.log(formData[i].robot[j])
                    alert("Please type your robot names as 'amber'.")
                    return
                }
            }
            if (formData[i].robot.length > 10 && !formData[i].robot.includes(',')) {
                alert("Please separate robots with commas.")
                return
            }
            // formData[i].date = formData[i].date.toString()

            console.log('DATE:', formData[i].date)
            let formattedDate = moment(formData[i].date).format('M/D/YYYY');
            console.log('formattedDate:', formattedDate)
            formData[i].date = formattedDate
        }

        // console.log(formData)
        // return

        setLoading(true);
        setDisableSubmit(true);

        API.addJobToSheet({jobName: jobName.trim(), dustyFile: dustyFile.trim(), layerDefinition: layerDefinition.trim(), formData: formData, token: localStorage.getItem('access')})
            .then(function() {
                console.log("IT WORKED YAY")
                setLoading(false)
                setDisableSubmit(false)
                handleOpen()
                // setCurrentIndex(0)
                // setTimeout(() => {
                //     setFormData([{ day: 1, robot: '', date: new Date(), startTime: '07:00:00', endTime: '16:00:00', notes: '' }])
                //     handleChange(currentIndex, 'robot', '')
                // }, 2000);
            })
            .catch(function(err) {
                console.log("THERE WAS AN ERROR LOL")
                setLoading(false)
                setDisableSubmit(false)
                handleOpenErrMod()
                // alert('There was an error adding your data to the sheet. We apologize for the inconvenience.')
            })
    }

    useEffect(() => {
        if (currentIndex <= 0) {
            setDisableBack(true)
        } else {
            setDisableBack(false)
        }

        if (currentIndex >= formData.length-1) {
            setDisableNext(true)
        } else {
            setDisableNext(false)
        }

        let robotExists = true
        for (let i=0; i<formData.length; i++){
            if (formData[i].robot === '') {
                robotExists = false
            }
        }

        if (jobName === '' || dustyFile === '' || robotExists === false) {
            setDisableSubmit(true)
        } else {
            setDisableSubmit(false)
        }

    }, [currentIndex, jobName, dustyFile, layerDefinition, formData])

    return (
        <div className={classes.mainContainer}>
            <CustomHeader className={classes.header} content={"Add a new job (25.x)"}/>
            {/* <Typography className={classes.header} variant='h2'>
                Add a new job
            </Typography> */}
            {/* <CustomSubheader className={classes.subheader} content={"Submit data to create a new QA report"}/>
            <a href="https://docs.google.com/spreadsheets/d/12osaUz6Qv8N9-QC51loF0QQ59oodNQO_qxmRdiYr5cI/edit?usp=sharing" target="_blank"><ListAltIcon className={classes.listIcon} /></a> */}
            <Typography className={classes.subheader} variant='h2'>
                Create a new QA report <a href="https://docs.google.com/spreadsheets/d/12osaUz6Qv8N9-QC51loF0QQ59oodNQO_qxmRdiYr5cI/edit?usp=sharing" target="_blank"><ListAltIcon className={classes.listIcon} /></a>
            </Typography>
            <Typography
                className={classes.subheader2}
                // variant='h2'
                style={{
                    margin: 'auto',
                    display: 'inline-block',
                    width: 'fit-content !important',
                    fontFamily: 'Roboto !important', 
                    fontWeight: '500', 
                    backgroundColor: '#f6862a', 
                    borderRadius: '20px', 
                    padding: '4px', 
                    paddingLeft: '10px',
                    paddingRight: '15px',
                }}
            >
                ***For 1.0 jobs, use the <a href="/uploadDusty" style={{color: '#0b59d9'}}>new tool</a>
            </Typography>
            <form>
                <div className={classes.formContainer}>
                    <div className={classes.leftContainer}>
                        <div className={classes.jobInfoContainer}>
                            <p>Job name</p>
                            <TextField 
                                name='jobName'
                                className={classes.textField}
                                defaultValue={formData[currentIndex].jobName}
                                onChange={(e) => setJobName(e.target.value)}
                                variant="outlined"
                                size="small"
                            />
                            <p>JSON/.dusty <span className={classes.helpText}>(link to file)</span></p>
                            <TextField 
                                name='dustyFile'
                                className={classes.textField}
                                // defaultValue={formData[currentIndex].dustyFile}
                                onChange={(e) => setDustyFile(e.target.value)}
                                variant="outlined"
                                size="small"
                            />
                            <p>Layer definition <span className={classes.helpText}>(optional link)</span></p>
                            <TextField 
                                name='layerDefinition'
                                className={classes.textField}
                                // defaultValue={formData[currentIndex].layerDefinition}
                                onChange={(e) => setLayerDefinition(e.target.value)}
                                variant="outlined"
                                size="small"
                            />
                        </div>
                    </div>
                    <div className={classes.rightContainer}>
                        <div
                            className={classes.dayContainer}
                            key={currentIndex}
                            // style={{ backgroundColor: currentIndex % 3 == 0 ? '#ffedbd' : '#fce8b1' }}
                            style={{ backgroundColor: currentIndex % 3 === 0 ? '#ffedbd' : currentIndex % 3 === 1 ? '#ffe8ab' : '#ffe39c' }}
                        >
                            { (formData.length > 1 && currentIndex > 0) && (
                                <div className={classes.closeDiv} onClick={handleRemoveDay}>
                                    <Button className={classes.closeBtn}>
                                        <CloseIcon className={classes.closeIcon}/>
                                    </Button>
                                </div>
                            )}
                            <p>Day</p>
                            <TextField 
                                name='day'
                                type='number'
                                onWheel={(e) => e.target.blur()}
                                className={classes.textField}
                                defaultValue={formData[currentIndex].day}
                                onChange={(e) => handleChange(currentIndex, 'day', e.target.value)}
                                variant="outlined"
                                size="small"
                            />
                            <p>Robots <span className={classes.helpText}>(i.e amber9, amber12)</span></p>
                            <TextField 
                                name='robot'
                                className={classes.textField}
                                defaultValue={formData[currentIndex].robot}
                                onChange={(e) => handleChange(currentIndex, 'robot', e.target.value)}
                                variant="outlined"
                                size="small"
                            />
                            <p>Date <span className={classes.helpText}>(select)</span></p>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    inputFormat="M/d/yyyy"
                                    // mask="_/_/____"
                                    // inputVariant="outlined"
                                    value={formData[currentIndex].date}
                                    onChange={(newDate) => handleChange(currentIndex, 'date', newDate)}
                                    renderInput={(params) => <TextField className={classes.textField} variant="outlined" size="small" {...params} />}
                                />
                            </LocalizationProvider>
                            <p>Start / End time <span className={classes.helpText}>(Pacific)</span></p>
                            <div style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'}}>
                                <FormControl size="small">
                                    <Select
                                        name="startTime"
                                        className={classes.textFieldTime}
                                        value={formData[currentIndex].startTime}
                                        onChange={(e) => handleChange(currentIndex, 'startTime', e.target.value)}
                                        variant="outlined"
                                        size="small"
                                        MenuProps={{ classes: { paper: classes.menuPaper } }}
                                    >
                                        <MenuItem value={'01:00:00'}>1 AM</MenuItem>
                                        <MenuItem value={'02:00:00'}>2 AM</MenuItem>
                                        <MenuItem value={'03:00:00'}>3 AM</MenuItem>
                                        <MenuItem value={'04:00:00'}>4 AM</MenuItem>
                                        <MenuItem value={'05:00:00'}>5 AM</MenuItem>
                                        <MenuItem value={'06:00:00'}>6 AM</MenuItem>
                                        <MenuItem value={'07:00:00'}>7 AM</MenuItem>
                                        <MenuItem value={'08:00:00'}>8 AM</MenuItem>
                                        <MenuItem value={'09:00:00'}>9 AM</MenuItem>
                                        <MenuItem value={'10:00:00'}>10 AM</MenuItem>
                                        <MenuItem value={'11:00:00'}>11 AM</MenuItem>
                                        <MenuItem value={'12:00:00'}>12 PM</MenuItem>
                                        <MenuItem value={'13:00:00'}>1 PM</MenuItem>
                                        <MenuItem value={'14:00:00'}>2 PM</MenuItem>
                                        <MenuItem value={'15:00:00'}>3 PM</MenuItem>
                                        <MenuItem value={'16:00:00'}>4 PM</MenuItem>
                                        <MenuItem value={'17:00:00'}>5 PM</MenuItem>
                                        <MenuItem value={'18:00:00'}>6 PM</MenuItem>
                                        <MenuItem value={'19:00:00'}>7 PM</MenuItem>
                                        <MenuItem value={'20:00:00'}>8 PM</MenuItem>
                                        <MenuItem value={'21:00:00'}>9 PM</MenuItem>
                                        <MenuItem value={'22:00:00'}>10 PM</MenuItem>
                                        <MenuItem value={'23:00:00'}>11 PM</MenuItem>
                                        <MenuItem value={'24:00:00'}>12 PM</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl size="small">
                                    <Select
                                        name="endTime"
                                        className={classes.textFieldTime}
                                        value={formData[currentIndex].endTime}
                                        onChange={(e) => handleChange(currentIndex, 'endTime', e.target.value)}
                                        variant="outlined"
                                        size="small"
                                        MenuProps={{ classes: { paper: classes.menuPaper } }}
                                    >
                                        <MenuItem value={'01:00:00'}>1 AM</MenuItem>
                                        <MenuItem value={'02:00:00'}>2 AM</MenuItem>
                                        <MenuItem value={'03:00:00'}>3 AM</MenuItem>
                                        <MenuItem value={'04:00:00'}>4 AM</MenuItem>
                                        <MenuItem value={'05:00:00'}>5 AM</MenuItem>
                                        <MenuItem value={'06:00:00'}>6 AM</MenuItem>
                                        <MenuItem value={'07:00:00'}>7 AM</MenuItem>
                                        <MenuItem value={'08:00:00'}>8 AM</MenuItem>
                                        <MenuItem value={'09:00:00'}>9 AM</MenuItem>
                                        <MenuItem value={'10:00:00'}>10 AM</MenuItem>
                                        <MenuItem value={'11:00:00'}>11 AM</MenuItem>
                                        <MenuItem value={'12:00:00'}>12 PM</MenuItem>
                                        <MenuItem value={'13:00:00'}>1 PM</MenuItem>
                                        <MenuItem value={'14:00:00'}>2 PM</MenuItem>
                                        <MenuItem value={'15:00:00'}>3 PM</MenuItem>
                                        <MenuItem value={'16:00:00'}>4 PM</MenuItem>
                                        <MenuItem value={'17:00:00'}>5 PM</MenuItem>
                                        <MenuItem value={'18:00:00'}>6 PM</MenuItem>
                                        <MenuItem value={'19:00:00'}>7 PM</MenuItem>
                                        <MenuItem value={'20:00:00'}>8 PM</MenuItem>
                                        <MenuItem value={'21:00:00'}>9 PM</MenuItem>
                                        <MenuItem value={'22:00:00'}>10 PM</MenuItem>
                                        <MenuItem value={'23:00:00'}>11 PM</MenuItem>
                                        <MenuItem value={'24:00:00'}>12 PM</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <p>Notes <span className={classes.helpText}>(operator, job type)</span></p>
                            <TextField 
                                name='notes'
                                className={classes.textField}
                                defaultValue={formData[currentIndex].notes}
                                onChange={(e) => handleChange(currentIndex, 'notes', e.target.value)}
                                variant="outlined"
                                size="small"
                            />
                        </div>
                        <div className={classes.navBtnContainer}>
                            <Button className={classes.arrowBtn} onClick={() => setCurrentIndex(currentIndex - 1)} disabled={disableBack}>
                                <ArrowBackIcon />
                            </Button>
                            <p>Day {currentIndex+1} of {formData.length}</p>
                            <Button className={classes.arrowBtn} onClick={() => setCurrentIndex(currentIndex + 1)} disabled={disableNext}>
                                <ArrowForwardIcon />
                            </Button>
                        </div>
                        <div className={classes.submitContainer}>
                            <p className={classes.linkText} onClick={handleAddDay}>Add new day</p>
                            <br />
                            {loading && (
                                <CircularProgress className={classes.loadingCircle} size={25}/>
                            )}
                            <SubmitButton className={classes.submitBtn} onClick={handleSubmit} disabled={disableSubmit}>Submit</SubmitButton>
                        </div>
                    </div>
                </div>
            </form>
            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                <Box sx={modalStyle}>
                    <CheckCircleIcon style={{color: 'green', fontSize: '30px', marginBottom: '10px'}}/>
                    <Typography variant="h6" component="h2">
                        Success!
                    </Typography>
                    <Typography sx={{ mt: 2 }}>
                        Your data has been sent to the sheet. QA report will be generated shortly.
                    </Typography>
                </Box>
                </Fade>
            </Modal>

            <Modal
                open={openErrMod}
                onClose={handleCloseErrMod}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openErrMod}>
                <Box sx={modalStyle}>
                    <ErrorIcon style={{color: 'red', fontSize: '30px', marginBottom: '10px'}}/>
                    <Typography variant="h6" component="h2">
                        Something went wrong..
                    </Typography>
                    <Typography sx={{ mt: 2 }}>
                        There was an error adding your data to the sheet. We apologize for the inconvenience.
                    </Typography>
                </Box>
                </Fade>
            </Modal>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            // margin: theme.spacing(1),
        }
    },
    mainContainer: {
        width: '85vw',
        margin: 'auto',
        // display: 'flex'
        marginBottom: '100px'
    },
    formContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: '20px',
        width: '100%',
    },
    leftContainer: {
        marginBottom: '20px',
        // width: '100%',
        '@media (max-width:375px)': {
            width: '100%'
        },
    },
    jobInfoContainer: {
        backgroundColor: "#fff9e8",
        // backgroundColor: '#fff2d9',
        padding: '20px',
        paddingTop: '25px',
        // width: '100%'
    },
    rightContainer: {
        // marginBottom: '20px',
        // width: '100%',
        '@media (max-width:375px)': {
            width: '100%'
        },
    },
    dayContainer: {
        // backgroundColor: currentIndex ? "#ffedbd" : "white",
        // backgroundColor: '#fff2d9',
        padding: '20px',
        paddingTop: '25px',
        position: 'relative',
        // width: '100%'
        // display: 'flex',
        // justifyContent: 'space-between',
    },
    midContainer: {
        // backgroundColor: "#4a4a4a",
        paddingLeft: '10px'
    },
    navBtnContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: 'white'
    },
    arrowBtn: {
        color: '#f6862a !important',
        borderRadius: '0px !important',
        '&:hover': {
            // backgroundColor: '#fae9bb'
            backgroundColor: '#f5f5f5 !important'
        },
        '&:disabled': {
            // backgroundColor: '#fae9bb'
            color: '#c4c4c4 !important'
        },
    },
    submitContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    closeDiv: {
        position: 'absolute',
        top: '0px',
        right: '0px',
    },
    closeBtn: {
        backgroundColor: '#f6862a !important',
        borderRadius: '0px 0px 0px 5px !important',

        maxWidth: '55px',
        minWidth: '55px',
        height: '35px !important',
        '&:hover': {
            // backgroundColor: '#fae9bb'
            backgroundColor: '#eb8028 !important'
        },
    },
    closeIcon: {
        // maxWidth: '30px',
        // minWidth: '30px',
        // paddingTop: '8px',
        // color: '#f6862a',

        paddingTop: '0px',
        color: 'white',
    },
    header: {
        // fontSize: '50px',
        // textAlign: 'left',
    },
    subheader: {
        fontSize: '25px !important',
        textAlign: 'left',
        // '@media (max-width:450px)': {
        //     fontSize: '21px !important',
        // },
        '@media (max-width:600px)': {
            fontSize: 'max(4vw, 20px) !important',
        },
    },
    subheader2: {
        marginTop: '8px !important',
        fontSize: '16px !important',
        textAlign: 'left',
        color: 'white',
        width: 'auto !important',
        // '@media (max-width:450px)': {
        //     fontSize: '21px !important',
        // },
        '@media (max-width:600px)': {
            fontSize: 'max(3vw, 15px) !important',
        },
    },
    listIcon: {
        // verticalAlign: 'middle',
        // marginBottom: '5px'
        color: '#ffa412',
        cursor: 'pointer',
        '&:hover': {
            // backgroundColor: '#fae9bb'
            color: '#ffb43b',
            // dropShadow: '0 0 5px rgba(0,0,0,0.25)',
        },
    },
    projectTitle: {
        fontSize: '30px !important',
        textAlign: 'left',
    },
    textField: {
        width: '250px',
        '@media (max-width:375px)': {
            width: '100%',
        },
    },
    textFieldTime: {
        width: '125px',
        '@media (max-width:375px)': {
            // width: '100%',
            width: '135px',

        },
    },
    helpText: {
        color: '#919191',
        // fontSize: '12px'
    },
    linkText: {
        color: '#ff9408',
        cursor: 'pointer',
        '&:hover': {
            // backgroundColor: '#fae9bb'
            color: '#fca430'
        },
    },
    menuPaper: {
        maxHeight: '200px !important'
    },
    submitBtn: {
        // float: 'right',
        // margin: 'auto',
        marginTop: '10px !important',
        display: 'flex',
        backgroundColor: '#ff8929 !important',
        color: 'white !important',
        '&:hover': {
            backgroundColor: '#f28022 !important'
        },
        '&:disabled': {
            color: '#adadad !important',
            borderColor: 'gray !important',
            backgroundColor: '#d4d4d4 !important'
        },
    },
    loadingCircle: {
        marginTop: '16px',
        // marginLeft: '10px',
        // fontSize: '10px',
        color: 'gray'
        // verticalAlign: 'middle'
    },
    version: {
        position: 'absolute',
        bottom: '5px',
        right: '10px',
        color: '#666666',
        fontSize: '10px !important',
    }
}))

const SubmitButton = withStyles((theme) => ({
    root: {
      '&:hover': {
        backgroundColor: '#f28022'
      },
      '&.Mui-disabled': {
        color: '#adadad',
        borderColor: 'gray',
        backgroundColor: '#d4d4d4'
      },
    },
}))(Button);

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
    ['@media (max-width:800px)']: { // eslint-disable-line no-useless-computed-key
        width: '75%'
    }
};  

export default JobForm
