import React, {useEffect} from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Typography, Paper, Button } from '@mui/material';
import { withStyles, makeStyles, styled } from '@mui/styles'
import dustyLogo from '../images/dusty-logo.png';

// import dotenv from 'dotenv';

import API from '../API'
// import { fontSize } from '@mui/system';

// dotenv.config();



const Auth = () => {
    const classes = useStyles();
    const history = useHistory();
    
    useEffect(() => {
        API.verifyTokens({ token: localStorage.getItem('access'), refreshToken: localStorage.getItem('refresh')})
            .then(res => {
                console.log('VALID AUTH,', res.data)
                if (typeof res.data == 'object') {
                    localStorage.setItem('access', JSON.stringify(res.data))
                }
                history.push('/reports')
            })
            .catch(err => {
                console.log('auto-login declined')
                console.log(err)
                // setLoggedIn(false)
                // setUserInfo(null)
                // history.push('/')
            })
    }, [])

    const handleHttpAuth = () => {
        API.httpAuth()
            .then(res => {
                console.log('responseeeee')
                console.log(res.data)
                window.location.href = res.data
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <div>
            <div className={classes.mainContainer}>
                <Paper className={classes.paper} elevation={3}>
                    <img className={classes.logo} src={dustyLogo} />
                    <Typography className={classes.header} variant='h2'>
                        Dusty Robotics <br/>QA Portal
                    </Typography>
                    <form className={classes.form}>
                        <div className={classes.loginBtnContainer}>
                            {/* <Button 
                                variant="outlined"
                                className={classes.loginBtn}
                                onClick={() => handleHttpAuth()}
                            >
                                Log in
                            </Button> */}
                            <LoginBtn
                                variant="outlined"
                                className={classes.loginBtn}
                                onClick={() => handleHttpAuth()}
                            >
                                Log in
                            </LoginBtn>
                        </div>
                    </form>
                    <br />
                </Paper>
            </div>
            {/* <Typography className={classes.version}>Version 5.0</Typography> */}
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            // margin: theme.spacing(1),
        }
    },
    mainContainer: {
        width: '80vw',
        // margin: 'auto',
        margin: '0',
        position: 'absolute',
        top: '50%',
        left: '50%',
        WebkitTransform: 'translate(-50%, -50%)',
        transform: 'translate(-50%, -50%)'
    },
    paper: {
        margin: 'auto',
        padding: '50px',
        paddingLeft: '70px',
        paddingRight: '70px',
        maxWidth: '300px',
        width: '80%',
        '@media (max-width:550px)': {
            paddingLeft: '8vw',
            paddingRight: '8vw',
        },
        // height: '300px'
    },
    logo: {
        height: '80px',
        display: 'block',
        margin: 'auto',
        marginBottom: '20px',
        // justifyContent: 'center',
        // textAlign: 'center',
    },
    header: {
        fontSize: '30px !important',
        // margin: 'auto'
        textAlign: 'center'
    },
    switchBtn: {
        textDecoration: 'underline',
        color: '#0645AD',
    },
    textField: {
        marginTop: '10px',
        width: '100%'
    },
    submitBtn: {
        backgroundColor: 'black',
        color: 'white',
        // width: '100%',
        marginTop: '10px',
        margin: 'auto'
    },
    form: {
        justifyContent: 'center',
        margin: 'auto',
    },
    loginBtnContainer: {
        // margin: '0 auto',
        textAlign: 'center',
        // justifyContent: 'center',
        marginTop: '10px',
    },
    loginBtn: {
        // bottom: '0px',
        margin: '0'
        // margin: '0 auto',
        // textAlign: 'center',
        // justifyContent: 'center',
    },
    version: {
        position: 'absolute',
        bottom: '5px',
        right: '10px',
        color: '#666666',
        fontSize: '10px !important',
    }
}));

const SpecialButton = withStyles(() => ({
    root: {
        backgroundColor: 'red',
        '&:hover': {
            backgroundColor: 'gray'
        },
        '&.Mui-disabled': {
            // backgroundColor: 'gray'
        }
    }
}))(Button);

const LoginBtn = styled(Button)({
    // backgroundColor: 'red !important',
    padding: '6px 12px !important',
    color: '#f6862a !important',
    borderColor: '#f6862a !important',
    '&:hover': {
        backgroundColor: '#faf6f2 !important',
        // borderColor: '#0062cc',
        boxShadow: 'none',
    },
})

export default Auth
