// import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Switch, Route, useRouteMatch } from 'react-router-dom';
import { Typography, Button, CircularProgress, FormControl, TextField, Divider, Select, MenuItem } from '@mui/material';
import { withStyles, makeStyles } from '@mui/styles'
import "@fontsource/roboto";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/500.css';


import Auth from './pages/Auth';
import Navigation from './components/Navigation';
import LandingPage from './pages/LandingPage';
import AddJobPage from './pages/AddJobPage';
import ReportsPage from './pages/ReportsPage';
import ReportInfoPage from './pages/ReportInfoPage';
import LayerDefPage from './pages/LayerDefPage';
import UploadDustyPage from './pages/UploadDustyPage';

function App() {
  const classes = useStyles();

  
  const ReportsNav = () => {
    let match = useRouteMatch();

    return (
      <Switch>
        <Route path={`${match.path}/:reportName`}>
          <ReportInfoPage />
        </Route>
        <Route path={match.path}>
          <ReportsPage />
        </Route>
      </Switch>
    )
  }

  return (
    <>
      <BrowserRouter>
        <Navigation />
        <div style={{ paddingTop: '90px' }}>
          <Switch>
            <Route path='/' exact component={Auth} />
            {/* <Route path='/jobs' exact component={Jobs} /> */}
            {/* <Route path='/profile' component={Profile} />
            <Route path='/current' component={CurrentJobs} />
            <Route path='/previous' component={PreviousJobs} /> */}
            <Route path='/oauth2callback' exact component={LandingPage} />
            <Route path='/uploadDusty' exact component={UploadDustyPage} />
            <Route path='/layerDef' exact component={LayerDefPage} />
            <Route path='/addJob' exact component={AddJobPage} />
            <Route path='/reports'>
              <ReportsNav />
            </Route>
          </Switch>
        </div>
      </BrowserRouter>
      <Typography className={classes.version}>Version 6.53</Typography>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      '& > *': {
          // margin: theme.spacing(1),
      }
  },
  version: {
      position: 'fixed',
      bottom: '5px',
      right: '10px',
      color: '#666666',
      fontSize: '10px !important',
  }
}))

export default App;
