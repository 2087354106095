import React, {useEffect} from 'react'
import { CircularProgress } from '@mui/material';

import { useHistory, useLocation } from 'react-router-dom';

import API from '../API'

const LandingPage = (props) => {
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        // Getting auth code from url
        const params = location.search.substring(1)
        const code = params.split('&')[0].substring(5)
        console.log(code)

        API.getTokens({code: code})
            .then(res => {
                // console.log('obtained token')
                console.log(res.data)
                localStorage.setItem('access', JSON.stringify(res.data.access))
                if (res.data.refresh) {
                    localStorage.setItem('refresh', JSON.stringify(res.data.refresh))
                }
                history.push('/reports')
            })
            .catch(err => {
                console.log(err)
                // console.log('could not get token')
                history.push('/')
            })

    }, [])

    return (
        <div>
            <CircularProgress  style={{margin: 'auto', marginLeft: '30px', marginTop: '30px'}}/>
        </div>
    )
}

export default LandingPage
