import React, { useState, useEffect } from 'react';
import { Select, MenuItem, Typography, TextField, Button, FormControl, CircularProgress, Modal, Backdrop, Fade, Box, Divider } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles'
import CustomHeader from './CustomHeader';
import FileSaver from 'file-saver';
import CustomSubheader from './CustomSubheader';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';

import ListAltIcon from '@mui/icons-material/ListAlt';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import FolderRoundedIcon from '@mui/icons-material/FolderRounded';

import API from '../API';

const initialState = [{ day: 1, robot: '', date: new Date(), startTime: '07:00:00', endTime: '16:00:00', notes: '' }]

const JobForm = () => {
    const classes = useStyles();
    const [ jobName, setJobName ] = useState('');
    const [ dustyFile, setDustyFile ] = useState('');
    const [ layerDefinition, setLayerDefinition ] = useState('');

    const [ defLayers, setDefLayers ] = useState(null);
    const [ dustyFileName, setDustyFileName ] = useState(null);
    const [ newFileUrl, setNewFileUrl ] = useState('');

    // const [ formData, setFormData ] = useState(initialState);
    const [ currentIndex, setCurrentIndex ] = useState(0);

    const [ disableBack, setDisableBack ] = useState(true);
    const [ disableNext, setDisableNext ] = useState(true);
    const [ disableSubmit, setDisableSubmit ] = useState(false);

    const [loading, setLoading] = useState(false);
    const [copied, setCopied] = useState(false);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [openErrMod, setOpenErrMod] = useState(false);
    const handleOpenErrMod = () => setOpenErrMod(true);
    const handleCloseErrMod = () => setOpenErrMod(false);

    const handleChange = (index, key, value) => {
        // setFormData({ ...formData, [e.target.name]: e.target.value });

        let temp_state = [...defLayers]
        let temp_element = { ...temp_state[index]}
        temp_element[key] = value
        temp_state[index] = temp_element;
        setDefLayers(temp_state)

        // console.log('jobName', jobName)
        // console.log('dustyFile', dustyFile)
        // console.log('layerDefinition', layerDefinition)
        // console.log('defLayers', defLayers)
    }

    const handleSubmitLink = () => {
        console.log(dustyFile)

        API.readDustyFile({dustyLink: dustyFile})
            .then(function() {
                console.log("IT WORKED YAY")
                // setLoading(false)
                // setDisableSubmit(false)
                // handleOpen()
            })
            .catch(function(err) {
                console.log("THERE WAS AN ERROR LOL")
            })
    }

    const readFile = (e) => {
        // console.log(e.target.value)
        // let nameOfFile = e.target.files[0].name
        console.log(e.target.files[0])
        setDustyFileName(e.target.files[0].name)
        let brokenFileName = e.target.files[0].name.split('.')
        setDefLayers(null)

        const fileReader = new FileReader();
        fileReader.readAsText(e.target.files[0], "UTF-8");
        fileReader.onload = e => {
            let layers = [];
            let fileData = JSON.parse(e.target.result)

            if (brokenFileName[brokenFileName.length - 1] == "dusty") {
                console.log("IT'S A DUSTY FILE")
                layers = fileData.layers
            } else {
                console.log("IT'S A JSON FILE")
                fileData.primitives.forEach(element => {
                    if (!layers.includes(element.layer)) {
                        layers.push(element.layer)
                    }
                })
            }
            console.log(layers)
            // return

            let layersForDef = []
            let soffitGroup = ['soffit', 'text', 'finish', 'layer']
            let obstacleGroup = ['slab', 'edge', 'col', 'column', 'obstacle', 'opening']
            let gridGroup = ['grid', 'gridline']
            let mepGroup = ['mep']
            let framingGroup = ['framing', 'offset']


            if (brokenFileName[brokenFileName.length - 1] == "dusty") {
                console.log("IT'S A DUSTY FILE")
                layers.forEach(element => {
                    if (element.name.charAt(0) != "_") {
                        if (element.name.toLowerCase().includes('soffit') || element.name.toLowerCase().includes('text') || element.name.toLowerCase().includes('finish') || element.name.toLowerCase().includes('layer')) {
                            layersForDef.push({layerName: element.name, layerGroup: "Soffits, Finish, Text, and Misc."})
                        } else if (element.name.toLowerCase().includes('slab') || element.name.toLowerCase().includes('edge') || element.name.toLowerCase().includes('col') || element.name.toLowerCase().includes('column') || element.name.toLowerCase().includes('obstacle') || element.name.toLowerCase().includes('opening')) {
                            layersForDef.push({layerName: element.name, layerGroup: "Obstacles"})
                        } else if (element.name.toLowerCase().includes('grid') || element.name.toLowerCase().includes('gridline')) {
                            layersForDef.push({layerName: element.name, layerGroup: "Grid"})
                        } else if (element.name.toLowerCase().includes('mep')) {
                            layersForDef.push({layerName: element.name, layerGroup: "MEPF Points"})
                        } else if (element.name.toLowerCase().includes('framing') || element.name.toLowerCase().includes('offset')) {
                            layersForDef.push({layerName: element.name, layerGroup: "Framing"})
                            return
                        } else {
                            layersForDef.push({layerName: element.name, layerGroup: "None"})
                        }       
                    }
                })
            } else {
                console.log("IT'S A JSON FILE")
                layers.forEach(element => {
                    if (element.charAt(0) != "_") {
                        if (element.toLowerCase().includes('soffit') || element.toLowerCase().includes('text') || element.toLowerCase().includes('finish') || element.toLowerCase().includes('layer')) {
                            layersForDef.push({layerName: element, layerGroup: "Soffits, Finish, Text, and Misc."})
                        } else if (element.toLowerCase().includes('slab') || element.toLowerCase().includes('edge') || element.toLowerCase().includes('col') || element.toLowerCase().includes('column') || element.toLowerCase().includes('obstacle') || element.toLowerCase().includes('opening')) {
                            layersForDef.push({layerName: element, layerGroup: "Obstacles"})
                        } else if (element.toLowerCase().includes('grid') || element.toLowerCase().includes('gridline')) {
                            layersForDef.push({layerName: element, layerGroup: "Grid"})
                        } else if (element.toLowerCase().includes('mep')) {
                            layersForDef.push({layerName: element, layerGroup: "MEPF Points"})
                        } else if (element.toLowerCase().includes('framing') || element.toLowerCase().includes('offset')) {
                            layersForDef.push({layerName: element, layerGroup: "Framing"})
                            return
                        } else {
                            layersForDef.push({layerName: element, layerGroup: "None"})
                        }       
                    }
                })
            }
            console.log(layersForDef)
            setDefLayers(layersForDef)
        }
    }

    const handleSubmit = () => {
        console.log(defLayers)
        setDisableSubmit(true)
        setLoading(true)

        let sftm = [], obst = [], grid = [], mepf = [], fram = []

        defLayers.forEach(element => {
            if (element.layerGroup == "Soffits, Finish, Text, and Misc.") {
                if (sftm.length == 0) {
                    sftm.push("  Soffits, Finish, Text, and Misc.:\n")
                    sftm.push("    color: cornflowerblue\n")
                    sftm.push("    layers:\n")
                }
                sftm.push(`    - ${element.layerName}\n`)
            }
        })
        defLayers.forEach(element => {
            if (element.layerGroup == "Obstacles") {
                if (obst.length == 0) {
                    obst.push("  Obstacles:\n")
                    obst.push("    color: green\n")
                    obst.push("    layers:\n")
                }
                obst.push(`    - ${element.layerName}\n`)
            }
        })
        defLayers.forEach(element => {
            if (element.layerGroup == "Grid") {
                if (grid.length == 0) {
                    grid.push("  Grid:\n")
                    grid.push("    color: gray\n")
                    grid.push("    layers:\n")
                }
                grid.push(`    - ${element.layerName}\n`)
            }
        })
        defLayers.forEach(element => {
            if (element.layerGroup == "MEPF Points") {
                if (mepf.length == 0) {
                    mepf.push("  MEPF Points:\n")
                    mepf.push("    color: purple\n")
                    mepf.push("    layers:\n")
                }
                mepf.push(`    - ${element.layerName}\n`)
            }
        })
        defLayers.forEach(element => {
            if (element.layerGroup == "Framing") {
                if (fram.length == 0) {
                    fram.push("  Framing:\n")
                    fram.push("    color: blue\n")
                    fram.push("    layers:\n")
                }
                fram.push(`    - ${element.layerName}\n`)
            }
        })

        let txtString = "layer_definition:\n" + sftm.join('') + obst.join('') + grid.join('') + mepf.join('') + fram.join('')

        var blob = new Blob([txtString.substring(0, txtString.length - 1)], {type: "text/plain;charset=utf-8"});
        // FileSaver.saveAs(blob, "hello-world.txt");
        var theFile = new File([txtString.substring(0, txtString.length - 1)], "hi.txt", {type: "text/plain;charset=utf-8"});
        // console.log(theFile)

        const formData = new FormData();
        formData.append('file', blob)

        // console.log(formData)
        for (var key of formData.entries()) {
            console.log(key[0] + ', ' + key[1]);
        }

        // console.log(test)

        API.uploadLayerDef({layerFile: txtString.substring(0, txtString.length - 1), dustyFileName: dustyFileName, token: localStorage.getItem('access')})
            .then(function(res) {
                console.log("IT WORKED YAY")
                console.log('file url is:', res.data)
                setNewFileUrl(`https://drive.google.com/file/d/${res.data}`)
                setCopied(false)
                setLoading(false)
                setDisableSubmit(false)
                handleOpen()
            })
            .catch(function(err) {
                console.log("THERE WAS AN ERROR LOL")
                setLoading(false)
                setDisableSubmit(false)
                handleOpenErrMod()
            })
    }

    return (
        <div className={classes.mainContainer}>
            <CustomHeader className={classes.header} content={"Layer Definition Generator (25.x)"}/>
            {/* <Typography className={classes.header} variant='h2'>
                Add a new job
            </Typography> */}
            {/* <CustomSubheader className={classes.subheader} content={"Submit data to create a new QA report"}/>
            <a href="https://docs.google.com/spreadsheets/d/12osaUz6Qv8N9-QC51loF0QQ59oodNQO_qxmRdiYr5cI/edit?usp=sharing" target="_blank"><ListAltIcon className={classes.listIcon} /></a> */}
            <Typography className={classes.subheader} variant='h2'>
                Define layers for QA report <a href="https://drive.google.com/drive/folders/1WF0pJ9CVTek6madDQw7M4aGDW0Z-3Tj8?usp=sharing" target="_blank"><FolderRoundedIcon className={classes.listIcon} /></a>
            </Typography>
            <form>
                <div className={classes.formContainer}>
                    <div className={classes.fileInputContainer}>
                        <p style={{fontFamily: "Roboto"}}>Upload Dusty or JSON file:</p>
                        <input
                            type="file"
                            accept=".json,.dusty"
                            onChange={readFile}
                        />
                        {/* <br /> */}
                        {/* <TextField 
                            name='dustyFile'
                            className={classes.textField}
                            defaultValue={formData[currentIndex].dustyFile}
                            onChange={(e) => setDustyFile(e.target.value)}
                            variant="outlined"
                            size="small"
                        />
                        <br />
                        <button type="button" onClick={handleSubmitLink}>
                            submit
                        </button> */}
                    </div>
                    { defLayers && (
                        <>
                            <div className={classes.jobInfoContainer}>
                                    <>
                                        {defLayers.map((layer, index) => (
                                            <div key={layer.layerName}>
                                                <div 
                                                    className={classes.layerItemDiv} 
                                                >
                                                    <p 
                                                        style={{
                                                            marginTop: '5px',
                                                            marginRight: '20px',
                                                            // verticalAlign: 'middle',
                                                            color: layer.layerGroup == "None" && '#cccccc'
                                                        }}
                                                    >
                                                        {layer.layerName}
                                                    </p>
                                                    {/* style={{ backgroundColor: layer.layerGroup == "None" && '#dedede' }} */}
                                                    <Select
                                                        name="layerGroup"
                                                        className={classes.textFieldTime}
                                                        value={layer.layerGroup}
                                                        onChange={(e) => handleChange(index, 'layerGroup', e.target.value)}
                                                        variant="outlined"
                                                        size="small"
                                                        // style={{backgroundColor: layer.layerGroup == "Soffits, Finish, Text, and Misc." ? "#d4effa" : (layer.layerGroup == "Obstacles" ? "#d7fce6" : (layer.layerGroup == "Grid" ? "#dbdbdb" : (layer.layerGroup == "MEPF Points" ? "#f0d9ff" : (layer.layerGroup == "Framing" && "#d6dbff"))))}}
                                                        MenuProps={{ classes: { paper: classes.menuPaper } }}
                                                    >
                                                        <MenuItem value={'Soffits, Finish, Text, and Misc.'}>Soffits, Finish, Text, and Misc.</MenuItem>
                                                        <MenuItem value={'Obstacles'}>Obstacles</MenuItem>
                                                        <MenuItem value={'Grid'}>Grid</MenuItem>
                                                        <MenuItem value={'MEPF Points'}>MEPF Points</MenuItem>
                                                        <MenuItem value={'Framing'}>Framing</MenuItem>
                                                        <MenuItem value={'None'}>None</MenuItem>
                                                    </Select>
                                                </div>
                                                { index !== defLayers.length-1 && (
                                                    <Divider />
                                                )}
                                            </div>
                                        ))}
                                    </>
                            </div>
                            <div style={{display: 'flex'}}>
                                <SubmitButton className={classes.submitBtn} onClick={handleSubmit} disabled={disableSubmit}>Submit</SubmitButton>
                                { loading && (
                                    <div style={{marginTop: '10px'}}>
                                        <CircularProgress style={{width: '30px', height: '30px', verticalAlign: 'middle', marginTop: '5px', marginLeft: '10px',}} />
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </div>

            </form>
            {/* <form>
                <div className={classes.formContainer}>
                    <div className={classes.leftContainer}>
                        <div className={classes.jobInfoContainer}>
                            <p>Job name</p>
                            <TextField 
                                name='jobName'
                                className={classes.textField}
                                defaultValue={formData[currentIndex].jobName}
                                onChange={(e) => setJobName(e.target.value)}
                                variant="outlined"
                                size="small"
                            />
                            <p>JSON/.dusty <span className={classes.helpText}>(link to file)</span></p>
                            <TextField 
                                name='dustyFile'
                                className={classes.textField}
                                // defaultValue={formData[currentIndex].dustyFile}
                                onChange={(e) => setDustyFile(e.target.value)}
                                variant="outlined"
                                size="small"
                            />
                            <p>Layer definition <span className={classes.helpText}>(optional link)</span></p>
                            <TextField 
                                name='layerDefinition'
                                className={classes.textField}
                                // defaultValue={formData[currentIndex].layerDefinition}
                                onChange={(e) => setLayerDefinition(e.target.value)}
                                variant="outlined"
                                size="small"
                            />
                        </div>
                    </div>
                    <div className={classes.rightContainer}>
                        <div
                            className={classes.dayContainer}
                            key={currentIndex}
                            // style={{ backgroundColor: currentIndex % 3 == 0 ? '#ffedbd' : '#fce8b1' }}
                            style={{ backgroundColor: currentIndex % 3 === 0 ? '#ffedbd' : currentIndex % 3 === 1 ? '#ffe8ab' : '#ffe39c' }}
                        >
                            <p>Day</p>
                            <TextField 
                                name='day'
                                type='number'
                                onWheel={(e) => e.target.blur()}
                                className={classes.textField}
                                defaultValue={formData[currentIndex].day}
                                onChange={(e) => handleChange(currentIndex, 'day', e.target.value)}
                                variant="outlined"
                                size="small"
                            />
                            <p>Robots <span className={classes.helpText}>(i.e amber9, amber12)</span></p>
                            <TextField 
                                name='robot'
                                className={classes.textField}
                                defaultValue={formData[currentIndex].robot}
                                onChange={(e) => handleChange(currentIndex, 'robot', e.target.value)}
                                variant="outlined"
                                size="small"
                            />
                            <p>Date <span className={classes.helpText}>(select)</span></p>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DesktopDatePicker
                                    inputFormat="M/d/yyyy"
                                    // mask="_/_/____"
                                    // inputVariant="outlined"
                                    value={formData[currentIndex].date}
                                    onChange={(newDate) => handleChange(currentIndex, 'date', newDate)}
                                    renderInput={(params) => <TextField className={classes.textField} variant="outlined" size="small" {...params} />}
                                />
                            </LocalizationProvider>
                            <p>Start / End time <span className={classes.helpText}>(Pacific)</span></p>
                            <div style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'}}>
                                <FormControl size="small">
                                    <Select
                                        name="startTime"
                                        className={classes.textFieldTime}
                                        value={formData[currentIndex].startTime}
                                        onChange={(e) => handleChange(currentIndex, 'startTime', e.target.value)}
                                        variant="outlined"
                                        size="small"
                                        MenuProps={{ classes: { paper: classes.menuPaper } }}
                                    >
                                        <MenuItem value={'01:00:00'}>1 AM</MenuItem>
                                        <MenuItem value={'02:00:00'}>2 AM</MenuItem>
                                        <MenuItem value={'03:00:00'}>3 AM</MenuItem>
                                        <MenuItem value={'04:00:00'}>4 AM</MenuItem>
                                        <MenuItem value={'05:00:00'}>5 AM</MenuItem>
                                        <MenuItem value={'06:00:00'}>6 AM</MenuItem>
                                        <MenuItem value={'07:00:00'}>7 AM</MenuItem>
                                        <MenuItem value={'08:00:00'}>8 AM</MenuItem>
                                        <MenuItem value={'09:00:00'}>9 AM</MenuItem>
                                        <MenuItem value={'10:00:00'}>10 AM</MenuItem>
                                        <MenuItem value={'11:00:00'}>11 AM</MenuItem>
                                        <MenuItem value={'12:00:00'}>12 PM</MenuItem>
                                        <MenuItem value={'13:00:00'}>1 PM</MenuItem>
                                        <MenuItem value={'14:00:00'}>2 PM</MenuItem>
                                        <MenuItem value={'15:00:00'}>3 PM</MenuItem>
                                        <MenuItem value={'16:00:00'}>4 PM</MenuItem>
                                        <MenuItem value={'17:00:00'}>5 PM</MenuItem>
                                        <MenuItem value={'18:00:00'}>6 PM</MenuItem>
                                        <MenuItem value={'19:00:00'}>7 PM</MenuItem>
                                        <MenuItem value={'20:00:00'}>8 PM</MenuItem>
                                        <MenuItem value={'21:00:00'}>9 PM</MenuItem>
                                        <MenuItem value={'22:00:00'}>10 PM</MenuItem>
                                        <MenuItem value={'23:00:00'}>11 PM</MenuItem>
                                        <MenuItem value={'24:00:00'}>12 PM</MenuItem>
                                    </Select>
                                </FormControl>
                                <FormControl size="small">
                                    <Select
                                        name="endTime"
                                        className={classes.textFieldTime}
                                        value={formData[currentIndex].endTime}
                                        onChange={(e) => handleChange(currentIndex, 'endTime', e.target.value)}
                                        variant="outlined"
                                        size="small"
                                        MenuProps={{ classes: { paper: classes.menuPaper } }}
                                    >
                                        <MenuItem value={'01:00:00'}>1 AM</MenuItem>
                                        <MenuItem value={'02:00:00'}>2 AM</MenuItem>
                                        <MenuItem value={'03:00:00'}>3 AM</MenuItem>
                                        <MenuItem value={'04:00:00'}>4 AM</MenuItem>
                                        <MenuItem value={'05:00:00'}>5 AM</MenuItem>
                                        <MenuItem value={'06:00:00'}>6 AM</MenuItem>
                                        <MenuItem value={'07:00:00'}>7 AM</MenuItem>
                                        <MenuItem value={'08:00:00'}>8 AM</MenuItem>
                                        <MenuItem value={'09:00:00'}>9 AM</MenuItem>
                                        <MenuItem value={'10:00:00'}>10 AM</MenuItem>
                                        <MenuItem value={'11:00:00'}>11 AM</MenuItem>
                                        <MenuItem value={'12:00:00'}>12 PM</MenuItem>
                                        <MenuItem value={'13:00:00'}>1 PM</MenuItem>
                                        <MenuItem value={'14:00:00'}>2 PM</MenuItem>
                                        <MenuItem value={'15:00:00'}>3 PM</MenuItem>
                                        <MenuItem value={'16:00:00'}>4 PM</MenuItem>
                                        <MenuItem value={'17:00:00'}>5 PM</MenuItem>
                                        <MenuItem value={'18:00:00'}>6 PM</MenuItem>
                                        <MenuItem value={'19:00:00'}>7 PM</MenuItem>
                                        <MenuItem value={'20:00:00'}>8 PM</MenuItem>
                                        <MenuItem value={'21:00:00'}>9 PM</MenuItem>
                                        <MenuItem value={'22:00:00'}>10 PM</MenuItem>
                                        <MenuItem value={'23:00:00'}>11 PM</MenuItem>
                                        <MenuItem value={'24:00:00'}>12 PM</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <p>Notes <span className={classes.helpText}>(operator, job type)</span></p>
                            <TextField 
                                name='notes'
                                className={classes.textField}
                                defaultValue={formData[currentIndex].notes}
                                onChange={(e) => handleChange(currentIndex, 'notes', e.target.value)}
                                variant="outlined"
                                size="small"
                            />
                        </div>
                        <div className={classes.navBtnContainer}>
                            <Button className={classes.arrowBtn} onClick={() => setCurrentIndex(currentIndex - 1)} disabled={disableBack}>
                                <ArrowBackIcon />
                            </Button>
                            <p>Day {currentIndex+1} of {formData.length}</p>
                            <Button className={classes.arrowBtn} onClick={() => setCurrentIndex(currentIndex + 1)} disabled={disableNext}>
                                <ArrowForwardIcon />
                            </Button>
                        </div>
                        <div className={classes.submitContainer}>
                            {loading && (
                                <CircularProgress className={classes.loadingCircle} size={25}/>
                            )}
                            <SubmitButton className={classes.submitBtn} onClick={handleSubmit} disabled={disableSubmit}>Submit</SubmitButton>
                        </div>
                    </div>
                </div>
            </form> */}
            <Modal
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                <Box sx={modalStyle}>
                    <CheckCircleIcon style={{color: 'green', fontSize: '30px', marginBottom: '10px'}}/>
                    <Typography variant="h6" component="h2">
                        File has been uploaded!
                    </Typography>
                    <Typography sx={{ mt: 2 }}>
                        Your layer definition file is located <a href={newFileUrl} target="_blank" style={{color: '#f6862a'}}>here</a>.
                    </Typography>
                    <div style={{display: 'flex'}}>
                        <button 
                            className={classes.copyButton} 
                            onClick={() => {
                                navigator.clipboard.writeText(newFileUrl)
                                setCopied(true)
                            }}
                        >
                            Copy link
                        </button>
                        <p style={{
                            color: 'gray', 
                            fontSize: '12px', 
                            marginLeft: '10px', 
                            marginTop: '10px', 
                            marginBottom: '0px',
                            display: copied ? "block" : "none"
                        }}>Link copied!</p>
                    </div>
                </Box>
                </Fade>
            </Modal>

            <Modal
                open={openErrMod}
                onClose={handleCloseErrMod}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={openErrMod}>
                <Box sx={modalStyle}>
                    <ErrorIcon style={{color: 'red', fontSize: '30px', marginBottom: '10px'}}/>
                    <Typography variant="h6" component="h2">
                        Something went wrong..
                    </Typography>
                    <Typography sx={{ mt: 2 }}>
                        There was an error creating your layer definition file. We apologize for the inconvenience.
                    </Typography>
                </Box>
                </Fade>
            </Modal>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            // margin: theme.spacing(1),
        }
    },
    mainContainer: {
        width: '85vw',
        margin: 'auto',
        // display: 'flex'
        marginBottom: '100px'
    },
    formContainer: {
        // display: 'flex',
        // flexDirection: 'row',
        // flexWrap: 'wrap',
        marginTop: '20px',
        width: '100%',
        maxWidth: '750px',
    },
    leftContainer: {
        marginBottom: '20px',
        // width: '100%',
        '@media (max-width:375px)': {
            width: '100%'
        },
    },
    fileInputContainer: {
        backgroundColor: "#ffedbd",
        // backgroundColor: '#fff2d9',
        padding: '20px',
        // paddingTop: '25px',
        width: '280px',
        marginBottom: '10px',
        borderRadius: '10px',
    },
    jobInfoContainer: {
        backgroundColor: "#fff9e8",
        // backgroundColor: '#fff2d9',
        padding: '10px',
        // paddingTop: '25px',
        // width: '100%'
        // marginBottom: '10px',
        borderRadius: '10px',
    },
    layerItemDiv: {
        display: 'flex',
        flexWrap: 'no-wrap',
        justifyContent: 'space-between',
        height: '40px',
        padding: '10px',
    },
    rightContainer: {
        // marginBottom: '20px',
        // width: '100%',
        '@media (max-width:375px)': {
            width: '100%'
        },
    },
    dayContainer: {
        // backgroundColor: currentIndex ? "#ffedbd" : "white",
        // backgroundColor: '#fff2d9',
        padding: '20px',
        paddingTop: '25px',
        position: 'relative',
        // width: '100%'
        // display: 'flex',
        // justifyContent: 'space-between',
    },
    midContainer: {
        // backgroundColor: "#4a4a4a",
        paddingLeft: '10px'
    },
    navBtnContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: 'white'
    },
    arrowBtn: {
        color: '#f6862a !important',
        borderRadius: '0px !important',
        '&:hover': {
            // backgroundColor: '#fae9bb'
            backgroundColor: '#f5f5f5 !important'
        },
        '&:disabled': {
            // backgroundColor: '#fae9bb'
            color: '#c4c4c4 !important'
        },
    },
    submitContainer: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    closeDiv: {
        position: 'absolute',
        top: '0px',
        right: '0px',
    },
    closeBtn: {
        backgroundColor: '#f6862a !important',
        borderRadius: '0px 0px 0px 5px !important',

        maxWidth: '55px',
        minWidth: '55px',
        height: '35px !important',
        '&:hover': {
            // backgroundColor: '#fae9bb'
            backgroundColor: '#eb8028 !important'
        },
    },
    closeIcon: {
        // maxWidth: '30px',
        // minWidth: '30px',
        // paddingTop: '8px',
        // color: '#f6862a',

        paddingTop: '0px',
        color: 'white',
    },
    header: {
        // fontSize: '50px',
        // textAlign: 'left',
    },
    subheader: {
        fontSize: '25px !important',
        textAlign: 'left',
        // '@media (max-width:450px)': {
        //     fontSize: '21px !important',
        // },
        '@media (max-width:600px)': {
            fontSize: 'max(4vw, 20px) !important',
        },
    },
    listIcon: {
        // verticalAlign: 'middle',
        // marginBottom: '5px'
        color: '#ffa412',
        cursor: 'pointer',
        '&:hover': {
            // backgroundColor: '#fae9bb'
            color: '#ffb43b',
            // dropShadow: '0 0 5px rgba(0,0,0,0.25)',
        },
    },
    projectTitle: {
        fontSize: '30px !important',
        textAlign: 'left',
    },
    textField: {
        width: '250px',
        '@media (max-width:375px)': {
            width: '100%',
        },
    },
    textFieldTime: {
        width: '150px',
        '@media (max-width:550px)': {
            width: '35%',
            minWidth: '110px'
            // width: '135px',

        },
    },
    helpText: {
        color: '#919191',
        // fontSize: '12px'
    },
    linkText: {
        color: '#ff9408',
        cursor: 'pointer',
        '&:hover': {
            // backgroundColor: '#fae9bb'
            color: '#fca430'
        },
    },
    menuPaper: {
        maxHeight: '200px !important'
    },
    submitBtn: {
        // float: 'right',
        // margin: 'auto',
        marginTop: '10px !important',
        display: 'flex',
        backgroundColor: '#ff8929 !important',
        color: 'white !important',
        height: '40px',
        '&:hover': {
            backgroundColor: '#f28022 !important'
        },
        '&:disabled': {
            color: '#adadad !important',
            borderColor: 'gray !important',
            backgroundColor: '#d4d4d4 !important'
        },
    },
    loadingCircle: {
        marginTop: '16px',
        // marginLeft: '10px',
        // fontSize: '10px',
        color: 'gray'
        // verticalAlign: 'middle'
    },
    copyButton: {
        marginTop: '5px',
        border: '1px gray solid',
        borderRadius: '5px',
        backgroundColor: '#e6e6e6',
        padding: '4px',
        height: '25px',
        '&:hover': {
            backgroundColor: '#cfcfcf'
        },
        transition: "0.2s"
    },
    version: {
        position: 'absolute',
        bottom: '5px',
        right: '10px',
        color: '#666666',
        fontSize: '10px !important',
    }
}))

const SubmitButton = withStyles((theme) => ({
    root: {
      '&:hover': {
        backgroundColor: '#f28022'
      },
      '&.Mui-disabled': {
        color: '#adadad',
        borderColor: 'gray',
        backgroundColor: '#d4d4d4'
      },
    },
}))(Button);

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
    ['@media (max-width:800px)']: { // eslint-disable-line no-useless-computed-key
        width: '75%'
    }
};  

export default JobForm
