import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const CustomHeader = ({ content }) => {
    const classes = useStyles();

    return (
        <Typography className={classes.header} variant="h2">
            {content}
        </Typography>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            // margin: theme.spacing(1),
        }
    },
    header: {
        fontSize: '50px !important',
        textAlign: 'left',
        '@media (max-width:600px)': {
            fontSize: 'max(8vw, 40px) !important',
        },
    },
}))

export default CustomHeader
