import React, { useState, useEffect } from 'react';
import { Select, MenuItem, Typography, TextField, Button, FormControl, CircularProgress, Modal, Backdrop, Fade, Box, Divider } from '@mui/material';
import { makeStyles, withStyles } from '@mui/styles'
import CustomHeader from './CustomHeader';
import CustomSubheader from './CustomSubheader';

import FileBase64 from 'react-file-base64';
import FileSaver from 'file-saver';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import FolderRoundedIcon from '@mui/icons-material/FolderRounded';

import API from '../API';

const UploadDustyForm = () => {
    const classes = useStyles();
    const [ dustyFile, setDustyFile ] = useState('');
    const [ dustyFileName, setDustyFileName ] = useState(null);
    const [ dustyNameField, setDustyNameField ] = useState(null);
    const [ dustyJobNamesField, setDustyJobNamesField ] = useState(null);
    const [ fileContent, setFileContent ] = useState(null);
    const [ loadingError, setLoadingError ] = useState(null);

    const [ disableSubmit, setDisableSubmit ] = useState(true);

    const [loading, setLoading] = useState(false);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [openErrMod, setOpenErrMod] = useState(false);
    const handleOpenErrMod = () => setOpenErrMod(true);
    const handleCloseErrMod = () => setOpenErrMod(false);

    const msInterval = 10000;


    useEffect(() => {
        const interval = setInterval(() => {
            checkAuth()

            return () => clearInterval(interval);
        }, msInterval);
    })

    const checkAuth = (e) => {
        API.fetchReports({ token: localStorage.getItem('access')})
            .then(res => {
                console.log('good')
                setLoadingError(false)
            })
            .catch(err => {
                setLoadingError(true)
            })
    }


    const readFile = (e) => {
        // console.log(e.target.value)
        // let nameOfFile = e.target.files[0].name
        console.log(e.target.files[0])
        // setDustyFile(e.target.files[0])
        setDustyFileName(e.target.files[0].name)

        // if (e.target.files[0].name.substring())
        console.log(e.target.files[0].name.substring(e.target.files[0].name.length - 6))



        const fileReader = new FileReader();
        fileReader.readAsText(e.target.files[0], "UTF-8");
        fileReader.onload = e => {
            let string = e.target.result
            // console.log("e.target.result", string);
            if (string == "") {
                alert("Your dusty file is empty. Please upload a new one.")
            } else {
                let lines = string.split('\n');
                // console.log("lines", lines);
    
                let filtered = lines.filter(function (line) {
                    return line.indexOf('#') != 0;
                });
                // console.log("filtered", filtered);
    
                let formatted = JSON.parse(filtered.join('\n'))
                // console.log('formatted', formatted)
                // let validDashStyles = [0, 1, 2, 3, 5, 7]
    
                setDustyFile(formatted)
                // setDustyNameField(formatted.name)
                // setDustyJobNamesField(formatted.job_names)
                console.log(formatted)
                setDisableSubmit(false)
            }

        };
    }

    const handleSubmit = () => {
        let bannedCharacters = ["~", "`", "!", "@", "#", "$", "%", "^", "&", "*", "+", "|", "/", ",", "?"]
        // const reg = /^[\w\-\s\(\)]+$/
        // console.log(dustyFile)
        // return
        // setDisableSubmit(true)
        // setLoading(true)

        // const fd = new FormData()
        // fd.append("dustyFile", dustyFile)

        if (!/^[\w\-\s\(\)]+$/.test(dustyFileName.replace(".dusty", ""))) {
            console.log('invalid dusty file name:', dustyFileName.replace(".dusty", ""))
            alert("Your dusty file name must only contain letters, numbers, spaces, hyphens, underscores, and parentheses.")
        } else {
            setDisableSubmit(true)
            setLoading(true)
    
            API.uploadDustyFile({dustyFile: dustyFile, dustyFileName: dustyFileName, token: localStorage.getItem('access')})
            // API.uploadDustyFile(fd)
                .then(function(res) {
                    // console.log("IT WORKED")
                    console.log(res.data)
                    setLoading(false)
                    setDisableSubmit(false)
                    handleOpen()
                })
                .catch(function(err) {
                    console.log(err)
                    setLoading(false)
                    setDisableSubmit(false)
                    handleOpenErrMod()
                })
        }
    }

    return (
        <div className='hi'>
            <div className={classes.mainContainer}>
                <CustomHeader className={classes.header} content={"Create QA Report (1.0)"}/>
                <Typography className={classes.subheader} variant='h2'>
                    Upload a Dusty file to generate a report <a href="https://console.cloud.google.com/storage/browser/dusty-qc-reports-uploads" target="_blank"><FolderRoundedIcon className={classes.listIcon} /></a>
                </Typography>
                <Typography
                    className={classes.subheader2}
                    // variant='h2'
                    style={{
                        margin: 'auto',
                        display: 'inline-block',
                        width: 'fit-content !important',
                        fontFamily: 'Roboto !important', 
                        fontWeight: '500', 
                        backgroundColor: '#f6862a', 
                        borderRadius: '20px', 
                        padding: '4px', 
                        paddingLeft: '10px',
                        paddingRight: '15px',
                    }}
                >
                    ***For 25.x jobs, use <a href="/addJob" style={{color: '#545454'}}>this tool</a>
                </Typography>
                { !loadingError ? (
                    <form>
                        <div className={classes.formContainer}>
                            <div className={classes.fileInputContainer}>
                                {/* <p style={{fontFamily: "Roboto"}}>Upload Dusty file:</p> */}
                                <input
                                    type="file"
                                    accept=".dusty"
                                    onChange={readFile}
                                />
                                {/* <FileBase64 
                                    type="file"
                                    multiple={false}
                                    onDone={(res) => {
                                        console.log(res)
                                        setDustyFileName(res.name)
                                        // setDustyFile(res.base64)
                                        setDustyFile(res.file)
                                        if (res.name.substring(res.name.length - 6) == ".dusty") {
                                            setDisableSubmit(false)
                                        } else {
                                            setDisableSubmit(true)
                                        }
                                    }}
                                /> */}
                            </div>
                            <div style={{display: 'flex'}}>
                                <SubmitButton className={classes.submitBtn} onClick={handleSubmit} disabled={disableSubmit}>Upload</SubmitButton>
                                { loading && (
                                    <div style={{marginTop: '10px'}}>
                                        <CircularProgress style={{width: '30px', height: '30px', verticalAlign: 'middle', marginTop: '5px', marginLeft: '10px',}} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </form>
                ) : (
                    <>
                        <Typography variant="h2" className={classes.header} style={{marginTop: '30px'}}>Please refresh the page to upload.</Typography>
                        {/* <p>Try refreshing the page, otherwise this record may have been moved or deleted.</p> */}
                    </>
                )}


                <Modal
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={open}>
                    <Box sx={modalStyle}>
                        <CheckCircleIcon style={{color: 'green', fontSize: '30px', marginBottom: '10px'}}/>
                        <Typography variant="h6" component="h2">
                            File has been uploaded!
                        </Typography>
                        <Typography sx={{ mt: 2 }}>
                            Your QA report will be generated shortly.
                        </Typography>
                    </Box>
                    </Fade>
                </Modal>

                <Modal
                    open={openErrMod}
                    onClose={handleCloseErrMod}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={openErrMod}>
                    <Box sx={modalStyle}>
                        <ErrorIcon style={{color: 'red', fontSize: '30px', marginBottom: '10px'}}/>
                        <Typography variant="h6" component="h2">
                            Something went wrong..
                        </Typography>
                        <Typography sx={{ mt: 2 }}>
                            There was an error uploading your Dusty file.
                            {/* There was an error uploading your Dusty file. Make sure you have permission to upload to the <a href="https://console.cloud.google.com/storage/browser/dusty-qc-reports-uploads" target="_blank" style={{color: '#f6862a'}}>bucket</a>. */}
                        </Typography>
                    </Box>
                    </Fade>
                </Modal>
            </div>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            // margin: theme.spacing(1),
        }
    },
    mainContainer: {
        width: '85vw',
        // display: 'inline-block',
        margin: '0 auto',
        marginBottom: '100px'
    },
    formContainer: {
        // display: 'flex',
        // flexDirection: 'row',
        // flexWrap: 'wrap',
        marginTop: '20px',
        width: '100%',
        maxWidth: '750px',
    },
    leftContainer: {
        marginBottom: '20px',
        // width: '100%',
        '@media (max-width:375px)': {
            width: '100%'
        },
    },
    fileInputContainer: {
        backgroundColor: "#ffedbd",
        // backgroundColor: '#fff2d9',
        padding: '20px',
        // paddingTop: '25px',
        width: '280px',
        marginBottom: '10px',
        borderRadius: '10px',
    },
    header: {
        fontSize: '40px !important',
        textAlign: 'left',
        wordWrap: 'break-word',
        '@media (max-width:750px)': {
            // fontSize: '6vw !important'
            fontSize: 'max(6vw, 35px) !important'
        },
    },
    subheader: {
        fontSize: '25px !important',
        textAlign: 'left',
        // '@media (max-width:450px)': {
        //     fontSize: '21px !important',
        // },
        '@media (max-width:600px)': {
            fontSize: 'max(4vw, 20px) !important',
        },
    },
    subheader2: {
        marginTop: '8px !important',
        fontSize: '16px !important',
        textAlign: 'left',
        color: 'white',
        width: 'auto !important',
        // '@media (max-width:450px)': {
        //     fontSize: '21px !important',
        // },
        '@media (max-width:600px)': {
            fontSize: 'max(3vw, 15px) !important',
        },
    },
    listIcon: {
        // verticalAlign: 'middle',
        // marginBottom: '5px'
        color: '#ffa412',
        cursor: 'pointer',
        '&:hover': {
            // backgroundColor: '#fae9bb'
            color: '#ffb43b',
            // dropShadow: '0 0 5px rgba(0,0,0,0.25)',
        },
    },
    projectTitle: {
        fontSize: '30px !important',
        textAlign: 'left',
    },
    textField: {
        width: '250px',
        '@media (max-width:375px)': {
            width: '100%',
        },
    },
    menuPaper: {
        maxHeight: '200px !important'
    },
    submitBtn: {
        // float: 'right',
        // margin: 'auto',
        marginTop: '10px !important',
        display: 'flex',
        backgroundColor: '#ff8929 !important',
        color: 'white !important',
        height: '40px',
        '&:hover': {
            backgroundColor: '#f28022 !important'
        },
        '&:disabled': {
            color: '#adadad !important',
            borderColor: 'gray !important',
            backgroundColor: '#d4d4d4 !important'
        },
    },
    loadingCircle: {
        // marginTop: '16px',
        // marginLeft: '10px',
        // color: 'gray'
        // verticalAlign: 'middle'
    },
    copyButton: {
        marginTop: '5px',
        border: '1px gray solid',
        borderRadius: '5px',
        backgroundColor: '#e6e6e6',
        padding: '4px',
        height: '25px',
        '&:hover': {
            backgroundColor: '#cfcfcf'
        },
        transition: "0.2s"
    }
}))

const SubmitButton = withStyles((theme) => ({
    root: {
      '&:hover': {
        backgroundColor: '#f28022'
      },
      '&.Mui-disabled': {
        color: '#adadad',
        borderColor: 'gray',
        backgroundColor: '#d4d4d4'
      },
    },
}))(Button);

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
    ['@media (max-width:800px)']: { // eslint-disable-line no-useless-computed-key
        width: '75%'
    }
};  

export default UploadDustyForm
