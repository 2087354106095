import React from 'react'
import JobForm from '../components/JobForm'

const AddJobPage = () => {
    return (
        <div>
            <JobForm />
        </div>
    )
}

export default AddJobPage
