import React, { useState, useEffect, useCallback } from 'react';
import { Typography, Button, CircularProgress, Modal, Box } from '@mui/material';
import { withStyles, makeStyles } from '@mui/styles'
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import dateFormat from 'dateformat';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DescriptionIcon from '@mui/icons-material/Description';
import RefreshIcon from '@mui/icons-material/Refresh';
import EditIcon from '@mui/icons-material/Edit';
import BoltRoundedIcon from '@mui/icons-material/BoltRounded';
import DownloadIcon from '@mui/icons-material/Download';

import API from '../API'
import CustomHeader from '../components/CustomHeader';
import CustomSubheader from '../components/CustomSubheader';
import JobDetailsSection from '../components/JobDetailsSection';


const ReportInfoPage = () => {
    const classes = useStyles();
    const location = useLocation();

    const [loading, setLoading] = useState(true);
    const [loadingError, setLoadingError] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);

    const [pdfBase64, setPdfBase64] = useState(null);
    const [pdfBlobUrl, setPdfBlobUrl] = useState(null);
    const [reportInfo, setReportInfo] = useState(null);
    const [projectName, setProjectName] = useState(null);
    const [dustyFile, setDustyFile] = useState(null);
    const [dustyFileName, setDustyFileName] = useState(null);

    const [jobDetails, setJobDetails] = useState([]);
    const [timeOfFetch, setTimeOfFetch] = useState(null)
    const msInterval = 30000;

    const [ openModal, setOpenModal ] = useState(false);
    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);

    const handleLoadInfo = (reportName) => {
        // Use reportName to get reportInfo
        API.findRecord({reportName: reportName, token: localStorage.getItem('access')})
            .then(res => {
                // console.log(res.data)
                setLoading(false)
                setTimeOfFetch(new Date())
                setReportInfo(res.data)
                console.log("handleLoadInfo", res.data)

                // Use reportInfo to get QA report pdf
                handleGetReport(res.data.mediaLink)
            })
            .catch(err => {
                console.log(err)
                setLoading(false)
                setLoadingError(true)
                setReportInfo(null)
                // alert('Sorry, we were not able to find this report.')
            })
    }

    const handleGetReport = (mediaLink) => {
        // console.log(mediaLink)
        API.getReport({ mediaLink: mediaLink, token: localStorage.getItem('access') })
            .then(res => {
                console.log('handleGetReport', res)
                // console.log(res.data)

                function base64ToBlob( base64, type = "application/octet-stream" ) {
                  const binStr = atob( base64 );
                  const len = binStr.length;
                  const arr = new Uint8Array(len);
                  for (let i = 0; i < len; i++) {
                    arr[ i ] = binStr.charCodeAt( i );
                  }
                  return new Blob( [ arr ], { type: type } );
                }
                const blob = base64ToBlob( res.data, 'application/pdf' );
                const url = URL.createObjectURL( blob );
                // const pdfWindow = window.open("");
                // pdfWindow.document.write("<iframe width='100%' height='100%' src='" + url + "'></iframe>");
                
                setPdfBlobUrl(url)
                setPdfBase64(res.data)
            })
            .catch(err => {
                console.log(err)
                alert('Sorry, we were not able to load the pdf.')
            })
    }

    const handleGetJobDetails = (reportName) => {
        // use pdf report name to GET PROJECT NAME FROM 1.0 DUSTY UPLOADS GOOGLE SHEET
        API.getReportDetails({ reportName: reportName, token: localStorage.getItem('access') })
            .then(res => {
                let response = res.data
                console.log('handleGetJobDetails, project name:', response)
                if (response.length > 0) {
                    setProjectName(response.replace(/ /g,"+"))
                }

                // IF THE JOB NAME IS NOT IN THE GOOGLE SHEET (NOT 1.0), GET JOB DETAILS FROM 25.X SHEET
                if (response == "") {
                    API.getJobDetails({ reportName: reportName, token: localStorage.getItem('access') })
                        .then(res => {
                            console.log('jobDetails', res.data)
                            setJobDetails(res.data)
                        })
                        .catch(err => {
                            console.log(err)
                        })
                }
            })
            .catch(err => {
                console.log(err)
            })

    }

    const handleGetDustyFile = (reportName) => {
        API.getDustyFile({ reportName: reportName, token: localStorage.getItem('access') })
            .then(res => {
                console.log('handleGetDustyFile', res.data)
                setDustyFile(res.data)
            })
            .catch(err => {
                console.log(err)
            })
    }

    // ONLY RUNS ON 25.X PROJECTS
    const handleForceUpdate = () => {
        setUpdateLoading(true)
        
        // Getting report name from url
        const params = location.pathname.split('/')
        const reportName = params[2]
        // console.log(reportName)
        
        API.forceUpdateJob({ reportName: reportName, token: localStorage.getItem('access') })
            .then(res => {
                console.log(res)
                setUpdateLoading(false)
                setOpenModal(true)
                // setTimeout(() => {
                //     // Getting report name from url
                //     const params = location.pathname.split('/')
                //     const reportName = params[2]
                //     handleLoadInfo(reportName)
                // }, 4000);
            })
            .catch(err => {
                console.log(err)
                setUpdateLoading(false)
                alert("Sorry we could not process this request.")
            })
    }

    // ONLY RUNS ON 1.0 PROJECTS
    const handleReportUpdate = () => {
        setUpdateLoading(true)

        // Getting report name from url
        const params = location.pathname.split('/')
        const reportName = params[2]

        const dustyFileName = reportName.replace(".pdf", ".dusty")
        console.log(dustyFileName)
       
        API.updateReport({ dustyFileName: dustyFileName, token: localStorage.getItem('access') })
            .then(res => {
                console.log(res)
                setUpdateLoading(false)
                setOpenModal(true)
                // setTimeout(() => {
                //     // Getting report name from url
                //     const params = location.pathname.split('/')
                //     const reportName = params[2]
                //     handleLoadInfo(reportName)
                // }, 4000);
            })
            .catch(err => {
                console.log(err)
                setUpdateLoading(false)
                alert("Sorry we could not process this request.")
            })
    }

    const handleDownload = () => {
        var binary = atob(pdfBase64.replace(/\s/g, ''));
        var len = binary.length;
        var buffer = new ArrayBuffer(len);
        var view = new Uint8Array(buffer);
        for (var i = 0; i < len; i++) {
            view[i] = binary.charCodeAt(i);
        }

        // create the blob object with content-type "application/pdf"               
        var blob = new Blob( [view], { type: "application/pdf", title: 'testpdf' });
        var url = URL.createObjectURL(blob);

        window.open(url, "_blank");
        // var pdf_newTab = window.open("");
        // pdf_newTab.document.write("<html><head><title>Download File</title></head><body><iframe title='hello'  width='100%' height='100%' src='data:application/pdf;base64, " + pdfBase64 + "'></iframe></body></html>");
    }
    
    const fetchData = useCallback(async () => {
        // Getting pdf report name from url
        const params = location.pathname.split('/')
        const reportName = params[2]
        setDustyFileName(reportName.replace('.pdf', '.dusty'))
        // console.log(reportName)

        handleLoadInfo(reportName)
        handleGetJobDetails(reportName)
        handleGetDustyFile(reportName)
    }, [])

    useEffect(() => {
        console.log(reportInfo)
        
        // initial
        fetchData();

        // every 30 seconds after
        // const interval = setInterval(() => {
        //     fetchData();
        // }, msInterval);
        // return () => clearInterval(interval);
    }, [fetchData])

    return (
        <div>
            <div className={classes.mainContainer}>
                <Link
                    to={{
                        pathname: '/reports',
                    }}
                    // className={classes.routerLink}
                >
                    <Button className={classes.backArrowBtn}>
                        <ArrowBackIcon className={classes.backArrow}/>
                    </Button>
                </Link>
                { loading && (
                    <>
                        <br />
                        <CircularProgress />
                    </>
                )}
                { loadingError && (
                    <>
                        <Typography variant="h2" className={classes.header}>Sorry, we were not able to find a report</Typography>
                        <p>Try refreshing the page, otherwise this record may have been moved or deleted.</p>
                    </>
                )}
                { reportInfo !== null && (
                    <>
                        <Typography variant="h2" className={classes.header}>
                            {/* <DescriptionIcon className={classes.reportIcon}/> */}
                            {reportInfo.name.split('.').slice(0, -1).join('.').split('_').join(' ')}
                        </Typography>
                        {/* <Button onClick={()=> handleGetJobDetails(location.pathname.split('/')[2])}>get the report details</Button> */}
                        <Typography className={classes.fetchedAt}>
                            Fetched at {dateFormat(timeOfFetch, "h:MM TT")}
                        </Typography>
                        <div className={classes.dateContainer}>
                            {/* <div className={classes.refreshContainer}>
                                <p className={classes.dateTitle}>
                                    <RefreshIcon className={classes.dateIcon} />
                                    Last refresh:
                                </p>
                                <p className={classes.theDate}>Today @ {dateFormat(timeOfFetch, "h:MM:ss TT")}</p>
                            </div> */}
                            <div className={classes.updateContainer}>
                                <p className={classes.dateTitle}>
                                    <EditIcon className={classes.dateIcon} />
                                    Last updated:
                                </p>
                                <p className={classes.theDate}>{dateFormat(reportInfo.updated, `ddd, mmm dS`)} @ {dateFormat(reportInfo.updated, `h:MM TT`)}</p>
                            </div>
                            <div className={classes.forceUpdateContainer}>
                                { jobDetails.length > 0 && (
                                    <>
                                        <Button className={classes.forceUpdateBtn} onClick={() => handleForceUpdate()} disabled={updateLoading}><BoltRoundedIcon />update</Button>
                                        { updateLoading && (
                                            <CircularProgress style={{width: '30px', height: '30px', verticalAlign: 'middle', marginLeft: '10px'}} />
                                        )}
                                    </>
                                )}
                                { projectName && (
                                    <>
                                        <Button className={classes.forceUpdateBtn} onClick={() => handleReportUpdate()} disabled={updateLoading}><BoltRoundedIcon />update</Button>
                                        { updateLoading && (
                                            <CircularProgress style={{width: '30px', height: '30px', verticalAlign: 'middle', marginLeft: '10px'}} />
                                        )}
                                    </>
                                )}
                                {/* <div> */}
                                    { pdfBase64 && (
                                        <>
                                            <a
                                                className={classes.downloadLink}
                                                href={pdfBlobUrl}
                                                // download={`${reportInfo.name} (${(new Date()).toLocaleDateString()})`}
                                                download={reportInfo.name.replace(/(\.[\w\d_-]+)$/i, ` (${(new Date()).toLocaleDateString()})$1`)}
                                            >
                                                <Button
                                                    className={classes.downloadBtn}
                                                    style={{ 
                                                        backgroundColor: '#9c9c9c', 
                                                        // '&:hover': {color: 'red !important'} 
                                                    }} 
                                                    // onClick={() => handleDownload()}
                                                >
                                                    <DownloadIcon style={{marginRight: '3px'}}/> Report
                                                </Button>
                                                {/* <DownloadBtn onClick={() => {
                                                    setReady(!ready)
                                                    handleOpenModal()
                                                }}>
                                                    <GetAppIcon className={classes.downloadIcon}/>Download
                                                </DownloadBtn> */}
                                            </a>
                                        </>
                                    )}
                                    { dustyFile && (
                                        <>
                                            <a
                                                style={{ textDecoration: 'none' }}
                                                href={`data:text/json;charset=utf-8,${encodeURIComponent(
                                                    JSON.stringify(dustyFile)
                                                )}`}
                                                download={dustyFileName}
                                            >
                                                <Button
                                                    className={classes.dustyDownloadBtn}
                                                    style={{
                                                        backgroundColor: '#1d3e53',
                                                    }}
                                                    // onClick={() => handleDownload()}
                                                >
                                                    <DownloadIcon style={{marginRight: '3px'}}/> Dusty File
                                                </Button>
                                            </a>
                                        </>
                                    )}
                                {/* </div> */}
                                {/* <button onClick={() => handleGetJobDetails()}>get job details</button> */}
                            </div>
                        </div>
                        { pdfBase64 && (
                            <>
                                <div className={classes.pdfContainer}>
                                    {/* <object
                                        className={classes.browserPDF}
                                        type="application/pdf"
                                        data={`data:application/pdf;base64,${pdfBase64}`}
                                        // width="100%"
                                        // height="100%"
                                    >
                                    </object> */}
                                    <iframe
                                        className={classes.browserPDF}
                                        // type="application/pdf"
                                        src={pdfBlobUrl}
                                        frameBorder={0}
                                        // width="100%"
                                        // height="100%"
                                    >
                                    </iframe>
                                    {/* <iframe
                                        className={classes.browserPDF}
                                        // type="application/pdf"
                                        src={`data:application/pdf;base64,${pdfBase64}`}
                                        frameBorder={0}
                                        // width="100%"
                                        // height="100%"
                                    >
                                    </iframe> */}
                                </div>
                            </>
                        )}
                    </>
                )}
                { projectName !== null && (
                    <>
                        <iframe 
                            src={`https://dustyrobotics.cloud.looker.com/embed/dashboards/32?allow_login_screen=true&Project+Name="${projectName}"`}
                            frameBorder={0}
                            style={{
                                width: '100%',
                                height: '90vh',
                                
                            }}
                        />
                        {/* <iframe
                            src={`${reportInfo.selfLink}`}
                        /> */}
                    </>
                )}
                { jobDetails !== [] && (
                    <JobDetailsSection data={jobDetails} getDetailsFunc={fetchData}/>
                )}
            </div>
            <Modal
                open={openModal}
                onClose={handleCloseModal}
            >
                {/* <div className={classes.modalContainer}>
                    <Typography variant="h6" component="h2">
                        Success!
                    </Typography>
                    <Typography>
                        Your request is being processed. The QA report will be updated shortly.
                    </Typography>
                </div> */}
                <Box sx={modalStyle}>
                    <CheckCircleIcon style={{color: 'green', fontSize: '30px', marginBottom: '10px'}}/>
                    <Typography variant="h6" component="h2">
                        Success!
                    </Typography>
                    <Typography sx={{ mt: 2 }}>
                        Your request is being processed. The QA report will be updated shortly.
                    </Typography>
                </Box>
            </Modal>
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            // margin: theme.spacing(1),
        }
    },
    mainContainer: {
        width: '85vw',
        margin: 'auto',
        marginBottom: '200px',
    },
    backArrowBtn: {
        maxWidth: '30px !important',
        minWidth: '30px !important',
        paddingLeft: '0px !important',
        // textAlign: 'left',
        marginBottom: '20px !important',
        color: '#333132 !important',
    },
    backArrow: {
        paddingLeft: '0px',
        // textAlign: 'left',
        // fontSize: '20px',
    },
    header: {
        fontSize: '40px !important',
        textAlign: 'left',
        wordWrap: 'break-word',
        '@media (max-width:750px)': {
            // fontSize: '6vw !important'
            fontSize: 'max(6vw, 35px) !important'
        },
    },
    fetchedAt: {
        // fontSize: '40px !important',
        color: '#888',
        // textAlign: 'left',
        wordWrap: 'break-word',
        '@media (max-width:750px)': {
            // fontSize: '6vw !important'
            // fontSize: 'max(6vw, 35px) !important'
        },
    },
    reportIcon: {
        // verticalAlign: 'middle',
        marginRight: '5px',
        marginBottom: '2px',
        fontSize: '30px !important',
    },
    dateContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        marginTop: '15px',
    },
    refreshContainer: {
        // backgroundColor: 'white',
        // width: '200px',
        marginRight: '100px',
        '@media (max-width:750px)': {
            marginRight: '10%'
        },
        // '@media (max-width:429px)': {
        //     marginRight: '10px'
        // },
    },
    updateContainer: {
        // backgroundColor: 'white',
        // width: '200px',
        marginRight: '70px',
        '@media (max-width:750px)': {
            marginRight: '10%'
        },
        // '@media (max-width:429px)': {
        //     marginRight: '10px'
        // },
    },
    dateIcon: {
        verticalAlign: 'middle',
        marginRight: '5px',
        marginBottom: '3px',
    },
    dateTitle: {
        marginBottom: '5px',
        marginTop: '0px',
    },
    theDate: {
        marginTop: '5px',
        '@media (max-width:750px)': {
            fontSize: '13px !important',
        },
    },
    forceUpdateContainer: {
        paddingTop: '5px',
        '@media (max-width:500px)': {
            width: '100%'
        },
    },
    forceUpdateBtn: {
        // border: 'none',
        // borderRadius: '5px',
        // fontFamily: 'Roboto',
        cursor: 'pointer',
        color: 'white !important',
        backgroundColor: '#f6862a !important',
        // padding: '10px',
        paddingLeft: '3px !important',
        paddingRight: '10px !important',
        transition: 'background-color 0.25s',
        // '@media (max-width:386px)': {
        //     width: '50%',
        //     marginLeft: '0 !important',
        //     marginRight: '10px !important',
        //     marginBottom: '5px !important',
        //     // color: 'red !important'
        // },
        '&:hover': {
            backgroundColor: '#e0761f !important',
            // color: '#f6862a'
        },
        '&:disabled': {
            backgroundColor: '#c4c4c4 !important',
            color: '#dbdbdb !important',
            cursor: 'auto',
        },
    },
    downloadBtn: {
        // border: 'none',
        // borderRadius: '5px',
        // fontFamily: 'Roboto',
        // textTransform: 'none !important',
        cursor: 'pointer',
        color: 'white !important',
        // backgroundColor: '#9c9c9c !important',
        minWidth: '40px !important',
        // maxWidth: '40px !important',
        marginLeft: '10px !important',
        // padding: '10px',
        // '@media (max-width:386px)': {
        //     width: '40%',
        //     marginLeft: '0 !important',
        //     marginRight: '10px !important',
        // },
        paddingLeft: '5px !important',
        paddingRight: '9px !important',
        transition: 'background-color 0.25s',
        '&:hover': {
            backgroundColor: '#8a8a8a !important',
            // filter: 'brightness(85%)'
            // color: '#f6862a'
        },
        // '&:disabled': {
        //     backgroundColor: '#c4c4c4 !important',
        //     color: '#dbdbdb !important',
        //     cursor: 'auto',
        // },
    },
    dustyDownloadBtn: {
        // border: 'none',
        // borderRadius: '5px',
        // fontFamily: 'Roboto',
        // textTransform: 'none !important',
        cursor: 'pointer',
        color: 'white !important',
        // backgroundColor: '#9c9c9c !important',
        minWidth: '40px !important',
        // maxWidth: '40px !important',
        marginLeft: '10px !important',
        // padding: '10px',
        '@media (max-width:386px)': {
            marginLeft: '0 !important',
            marginTop: '10px !important',
            marginRight: '20px !important'
        },
        paddingLeft: '5px !important',
        paddingRight: '9px !important',
        transition: 'background-color 0.25s',
        '&:hover': {
            backgroundColor: '#102736 !important',
            // filter: 'brightness(85%)'
            // color: '#f6862a'
        },
    },
    downloadLink: {
        textDecoration: 'none'
    },
    pdfContainer: {
        width: '100%',
        // borderRadius: '0px 0px 5px 5px',
        // backgroundColor: 'white',
        maxHeight: '90vh',
        minHeight: '90vh',
        // margin: 'auto',
        // display: 'flex'
        marginTop: '10px',
        marginBottom: '50px',
        // overflow: 'hidden',
        // overflowX: 'auto',
        // overflowY: 'auto',
    },
    // pdfNav: {
    //     borderRadius: '5px 5px 0px 0px',
    //     display: 'flex',
    //     justifyContent: 'space-between',
    //     backgroundColor: '#454545',
    //     padding: '15px',
    // },
    browserPDF: {
        width: '100%',
        height: '90vh',
        // height: '100% !important',
        // display: 'block',
    },
    zoomIcon: {
        // color: 'white',
    },
    pdfReport: {
        width: '100px'
    },
    modalContainer: {
        backgroundColor: 'white',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        padding: '25px',
        borderRadius: '20px',
    }
}));

const SpecialButton = withStyles((theme) => ({
    root: {
        color: 'white',
        '&:hover': {
        // backgroundColor: 'rgba(246, 134, 42, 0.85)'
        },
        '&.Mui-disabled': {
        color: 'gray',
        // borderColor: 'gray',
        // backgroundColor: 'rgba(255, 255, 255, 0.15)'
        }
    },
}))(Button);

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    borderRadius: '10px',
    boxShadow: 24,
    p: 4,
    ['@media (max-width:800px)']: { // eslint-disable-line no-useless-computed-key
        width: '75%'
    }
};  

export default ReportInfoPage
