import React from 'react'
import LayerDefForm from '../components/LayerDefForm'

const LayerDefPage = () => {
    return (
        <div>
            <LayerDefForm />
        </div>
    )
}

export default LayerDefPage
