import React, {useState, useEffect} from 'react';
import { Typography, Button, CircularProgress, Modal, Box, TextField } from '@mui/material';
import { withStyles, makeStyles, styled } from '@mui/styles'
import dateFormat from 'dateformat';
import moment from 'moment';

import API from '../API'
import { v4 as uuidv4 } from 'uuid';

import CustomSubheader from './CustomSubheader';
import InsertDriveFileRoundedIcon from '@mui/icons-material/InsertDriveFileRounded';
import LayersRoundedIcon from '@mui/icons-material/LayersRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';

const JobDetailsSection = (props) => {
    const classes = useStyles();

    // const [rawJobDetails, setRawJobDetails] = useState(null)
    const [jobDetails, setJobDetails] = useState(null);
    const [combinedJobs, setCombinedJobs] = useState(null);
    const [jobFormData, setJobFormData] = useState(null);
    const [jobFormJobName, setJobFormJobName] = useState(null);
    const [jobFormDustyFile, setJobFormDustyFile] = useState('');
    const [jobFormLayerFile, setJobFormLayerFile] = useState('');
    const [placeholderNum, setPlaceholderNum] = useState(-1);
    const [ openModal, setOpenModal ] = useState(false);

    useEffect(() => {
        // console.log('ORIGINAL', props.data)

        sortAndStructureData(props.data)
    
    }, [props.data])

    const sortAndStructureData = (jobData) => {
        if (jobData.length == 0) {
            setJobDetails(null)
            return
        }
        // sort by day
        jobData.sort((a, b) => (a.jobData[1] > b.jobData[1]) ? 1 : -1)

        // STRUCTURE JOBS
        let dataObject = []

        for (let i=0; i<jobData.length; i++) {
            let currentJob = {}
            currentJob.name = jobData[i].jobData[0]
            currentJob.day = jobData[i].jobData[1]
            currentJob.robot = [jobData[i].jobData[2]]
            currentJob.date = jobData[i].jobData[3]
            currentJob.start = jobData[i].jobData[4]
            currentJob.end = jobData[i].jobData[5]
            currentJob.notes = jobData[i].jobData[6]
            currentJob.dustyFile = jobData[i].jobData[7]
            currentJob.layerDef = jobData[i].jobData[8]
            currentJob.sheetRowNum = jobData[i].sheetRowNum
            currentJob.toDelete = false
            dataObject.push(currentJob)
        }

        console.log('jobDetails', dataObject)
        setJobDetails(dataObject)

        let structured = JSON.parse(JSON.stringify(dataObject))
        // console.log(structured === dataObject)

        // COMBINE JOBS
        let combinedJobs = []
        combinedJobs.push(structured[0])
        if (structured.length <= 1) {
            setCombinedJobs(combinedJobs)
            console.log('done combining jobs:', combinedJobs)
        } else {
            for (let i=1; i<structured.length; i++) {
                if (structured[i].day == combinedJobs[combinedJobs.length-1].day) {
                    // console.log('they match')
                    let newArray = combinedJobs[combinedJobs.length-1].robot.concat(structured[i].robot)
                    combinedJobs[combinedJobs.length-1].robot = newArray
                } else {
                    // console.log('new day')
                    combinedJobs.push(structured[i])
                }
            }
            console.log('RESULT', combinedJobs)
            setCombinedJobs(combinedJobs)
        }
    }

    const handleSubmitEdit = () => {
        // VERIFY FIELDS HERE
        for (let i=0; i<jobFormData.length; i++) {
            let valid = true;
            let currentJob = jobFormData[i]

            // if currentJob is NOT toDelete, validate it
            if (currentJob.toDelete == false) {    
                // trim all whitespace
                Object.keys(currentJob).map(k => currentJob[k] = typeof currentJob[k] == 'string' ? currentJob[k].trim() : (Array.isArray(currentJob[k]) ? currentJob[k].map(s => s.trim()) : currentJob[k]));
                console.log(currentJob)
    
                // day must be a positive integer
                if (!Number.isInteger(Number(currentJob.day)) || Number(currentJob.day) < 0) {
                    alert('Day must be a positive integer.')
                    return
                }
                // robot must have 'amber', array of length 1, only numbers and letters
                if (!currentJob.robot.join(',').includes('amber') || !currentJob.robot.join(',').match(/^[0-9a-z]+$/)) {
                    alert("Robot must include 'amber' and can only include letters and numbers.")
                    return
                }
                // date must be 3 parts, correct length for each part, only integers
                let splitDate = currentJob.date.split('/')
                if (splitDate.length != 3) {
                    alert("Date must be in the format M/D/YYYY")
                    return
                }
                splitDate.map((d, index) => {
                    console.log(d.length)
                    if (!d.match(/^[0-9]+$/)) {
                        valid = false
                        alert("Incorrect date format.")
                        return
                    }
                    if (((index == 0 && d.length !== 1) && (index == 0 && d.length !== 2)) || ((index == 1 && d.length !== 1) && (index == 1 && d.length !== 2)) || (index == 2 && d.length !== 4)) {
                        valid = false
                        alert("Incorrect date format.")
                        return
                    }
                })
                if (valid == false) {
                    return
                }
                // start/end time must be 3 parts, length 2 for each part, only integers
                let splitStart = currentJob.start.split(':')
                let splitEnd = currentJob.end.split(':')
                if (splitStart.length != 3 || splitEnd.length != 3) {
                    alert("Start and end times must be in the format HH:MM:SS")
                    return
                }
                splitStart.map(t => {
                    if (!t.match(/^[0-9]+$/)) {
                        valid = false
                        alert("Start and end times must only contain numbers and colons.")
                        return
                    }
                    if (t.length != 2) {
                        valid = false
                        alert("Start and end times must be in the format HH:MM:SS")
                        return
                    }
                })
                splitEnd.map(t => {
                    if (!t.match(/^[0-9]+$/)) {
                        valid = false
                        alert("Start and end times must only contain numbers and colons.")
                        return
                    }
                    if (t.length != 2) {
                        valid = false
                        alert("Start and end times must be in the format HH:MM:SS")
                        return
                    }
                })

            }
        }

        console.log('valid jobFormData:', jobFormData)
        console.log('valid jobName:', jobFormJobName)
        console.log('valid dustyFile:', jobFormDustyFile)
        console.log('valid layerFile:', jobFormLayerFile)
        // return

        API.editJobDetails({ data: jobFormData, name: jobFormJobName, dustyFile: jobFormDustyFile, layerFile: jobFormLayerFile, token: localStorage.getItem('access') })
            .then(res => {
                console.log('SUCCESS', res)
                setOpenModal(false)
                props.getDetailsFunc()
            })
            .catch(err => {
                console.log('FAILED', err)
                alert("Unable to edit report. Please try again later.")
            })
    }
    const handleFieldChange = (event) => {
        // console.log(event.target.id)
        // console.log(event.target.name)
        // console.log(event.target.value)

        let index = parseInt(event.target.id)
        let property = event.target.name
        let newArr = [...jobFormData]
        if (event.target.name == "dustyFile" || event.target.name == "layerDef") {
            for (let i=0; i<newArr.length; i++) {
                newArr[i][property] = event.target.value
            }
        } else if (event.target.name == "robot") {
            newArr[index][property] = [event.target.value]
        } else {
            newArr[index][property] = event.target.value
        }

        setJobFormData(newArr)

        if (event.target.name == 'dustyFile') {
            setJobFormDustyFile(event.target.value)
        }
        if (event.target.name == 'layerDef') {
            setJobFormLayerFile(event.target.value)
        }
    }

    const handleDeleteDay = (rowNum) => {
        // setJobFormData(jobFormData.filter(item => item.sheetRowNum !== rowNum))
        let newArr = [...jobFormData]
        for (let i=0; i<newArr.length; i++) {
            if (newArr[i].sheetRowNum == rowNum) {
                console.log(rowNum)
                newArr[i].toDelete = true
            }
        }
        setJobFormData(newArr)
    }

    const handleAddDay = () => {
        setJobFormData([...jobFormData, { date: '', day: '', dustyFile: '', end: '', layerDef: '', name: '', notes: '', robot: [], sheetRowNum: placeholderNum, start: '', toDelete: false }])
        // setJobFormData(jobFormData.push())
        setPlaceholderNum(placeholderNum - 1)
    }

    return (
        <div className={classes.mainContainer}>
            { combinedJobs !== null && (
                <>
                    <div className={classes.headerContainer}>
                        <CustomSubheader content={"Job info"} />
                        <div className={classes.editContainer} onClick={() => {
                            setOpenModal(true) 
                            setJobFormData(jobDetails)
                            setJobFormJobName(jobDetails[0].name)
                            setJobFormDustyFile(jobDetails[0].dustyFile)
                            setJobFormLayerFile(jobDetails[0].layerDef)
                        }}>
                            <Typography style={{fontWeight: 330}}>Edit details</Typography>
                            <EditRoundedIcon style={{fontSize: '20px'}} />
                        </div>
                    </div>
                    <div className={classes.fileContainer}>
                        {/* <p>Dusty file: {jobDetails[0].jobData[7]}</p> */}
                        <a href={combinedJobs[0].dustyFile} target="_blank" style={{textDecoration: 'none', color: 'black'}}>
                            <div className={classes.dustyFile}>
                                    <InsertDriveFileRoundedIcon style={{fontSize: '22px'}}/>
                                    <Typography className={classes.fileName}>Dusty file</Typography>
                            </div>
                        </a>
                        { combinedJobs[0].layerDef !== "" && (
                            // <p>Layer def: {jobDetails[0].jobData[8]}</p>
                            <a href={combinedJobs[0].layerDef} target="_blank" style={{textDecoration: 'none', color: 'black'}}>
                                <div className={classes.dustyFile}>
                                    <LayersRoundedIcon style={{fontSize: '25px'}}/>
                                    <Typography className={classes.fileName}>Layer definition</Typography>
                                </div>
                            </a>
                        )}
                    </div>
                    <div className={classes.jobDetailsContainer}>
                        { combinedJobs.map((job, index) => (
                            <div className={classes.dayContainer} key={uuidv4()}>
                                <Typography className={classes.day}>Day {job.day}</Typography>
                                <Typography className={classes.date}>{moment(job.date, 'MM/DD/YYYY').format("MM/DD/YY")} <span className={classes.time}>{moment(job.start, 'HH:mm:ss').format("hA")} - {moment(job.end, 'HH:mm:ss').format("hA")}</span></Typography>
                                {/* <p>{job.jobData[2]}</p> */}
                                <Typography className={classes.robots}>{job.robot.join(', ')}</Typography>
                                <Typography className={classes.notes}>{job.notes}</Typography>
                            </div>
                        ))}
                    </div>
                    <Modal
                        open={openModal}
                        onClose={() => setOpenModal(false)}
                    >
                        <Box sx={modalStyle}>
                            {/* <Typography variant="h6" component="h2">
                                Edit details
                            </Typography>
                            <Typography>
                                Updates to the report may take a few minutes
                            </Typography> */}
                            {/* <div> */}
                            <div className={classes.modalContentContainer}>
                                <Typography style={{fontWeight: 500, fontSize: '25px'}}>{combinedJobs[0].name}</Typography>
                                
                                <form>
                                    <Typography className={classes.modalSubHead}>JSON/.dusty file:</Typography>
                                    <input name="dustyFile" id={-1} className={classes.fileFormField} onChange={handleFieldChange} defaultValue={combinedJobs[0].dustyFile}></input>
                                    <br />
                                    <Typography className={classes.modalSubHead}>Layer definition file:</Typography>
                                    <input name="layerDef" id={-1} className={classes.fileFormField} onChange={handleFieldChange} defaultValue={combinedJobs[0].layerDef}></input>
                                    {/* <button onClick={() => console.log(jobDetails)}>asdf</button> */}
                                    <div className={classes.tableContainer}>
                                        <table className={classes.modalTable}>
                                            <thead>
                                                <tr>
                                                    <th style={{fontFamily: 'Roboto !important', fontWeight: '500'}}>Day</th>
                                                    <th style={{fontFamily: 'Roboto !important', fontWeight: '500'}}>Robot</th>
                                                    <th style={{fontFamily: 'Roboto !important', fontWeight: '500'}}>Date</th>
                                                    <th style={{fontFamily: 'Roboto !important', fontWeight: '500'}}>Start Time</th>
                                                    <th style={{fontFamily: 'Roboto !important', fontWeight: '500'}}>End Time</th>
                                                    <th style={{fontFamily: 'Roboto !important', fontWeight: '500'}}>Notes</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                { jobFormData !== null && (
                                                    <>
                                                        {/* { jobFormData.filter(item => item.toDelete !== true).map((job, index) => ( */}
                                                        { jobFormData.map((job, index) => (
                                                            <>
                                                                <tr key={job.sheetRowNum} style={{display: job.toDelete && 'none'}}>
                                                                    {/* <th><p>{job.sheetRowNum}</p></th> */}
                                                                    <th><input name="day" className={classes.formField} id={index} onChange={handleFieldChange} defaultValue={job.day} style={{width: '25px'}}></input></th>
                                                                    <th><input name="robot" className={classes.formField} id={index} onChange={handleFieldChange} defaultValue={job.robot} style={{width: '80px'}}></input></th>
                                                                    <th><input name="date" className={classes.formField} id={index} onChange={handleFieldChange} defaultValue={job.date}  style={{width: '80px'}}></input></th>
                                                                    <th><input name="start" className={classes.formField} id={index} onChange={handleFieldChange} defaultValue={job.start} style={{width: '80px'}}></input></th>
                                                                    <th><input name="end" className={classes.formField} id={index} onChange={handleFieldChange} defaultValue={job.end}  style={{width: '80px'}}></input></th>
                                                                    <th><input name="notes" className={classes.formField} id={index} onChange={handleFieldChange} defaultValue={job.notes}  style={{width: '200px'}}></input></th>
                                                                    <th><DeleteIcon onClick={() => handleDeleteDay(job.sheetRowNum)} className={classes.deleteIcon}/></th>
            
                                                                </tr>
                                                            </>
                                                        ))}
                                                    </>
                                                )}
                                            </tbody>
                                        </table>
                                        <div style={{justifyContent: 'center', margin: 'auto', textAlign: 'center'}}>
                                            <AddButton className={classes.addBtn} onClick={() => handleAddDay()}>add row<AddCircleIcon /></AddButton>
                                        </div>
                                    </div>
                                    <br />
                                    <Button className={classes.modalEditBtn} onClick={() => handleSubmitEdit()}>submit<SendRoundedIcon style={{fontSize: '20px', marginLeft: '5px'}}/></Button>
                                    {/* <p onClick={() => console.log(jobFormData)}>hi</p> */}
                                </form>
                            </div>
                            {/* </div> */}
                        </Box>
                    </Modal>
                </>      
            )}

            
        </div>
    )
}


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            // margin: theme.spacing(1),
        }
    },
    headerContainer: {
        marginBottom: '10px',
    },
    editContainer: {
        display: 'flex',
        cursor: 'pointer',
        // backgroundColor: 'white',
        width: '110px',
        transition: 'color 0.25s',
        '&:hover': {
            color: '#eb781a',
            // color: '#f6862a'
        },
    },
    jobDetailsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    fileContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: '5px',
    },
    dustyFile: {
        backgroundColor: 'white',
        // width: '150px',
        padding: '10px',
        borderRadius: '10px',
        display: 'flex',
        marginRight: '10px',
        marginBottom: '10px',
        cursor: 'pointer',
        transition: 'background-color 0.25s',
        '&:hover': {
            backgroundColor: '#d4d4d4',
            // color: '#f6862a'
            // color: '#636363',
        },
    },
    // layerFile: {
    //     backgroundColor: 'white',
    //     // width: '150px',
    //     padding: '10px',
    //     borderRadius: '10px',
    //     display: 'flex',
    // },
    fileName: {
        // color: 'white',
    },
    dayContainer: {
        backgroundColor: '#f6862a',
        backgroundImage: 'linear-gradient(to top right, #f6af2a, #ed7818)',
        // backgroundImage: 'linear-gradient(to top right, #737373, #f6862a)',
        marginRight: '20px',
        marginBottom: '20px',
        padding: '25px',
        borderRadius: '10px',
        minWidth: '250px',
        maxWidth: '250px',
        // height: '150px',
        transition: 'background-image 0.25s ease-in-out',
        '&:hover': {
            // backgroundImage: 'linear-gradient(to top right, gray, #e66317)',
            // color: '#f6862a'
            // color: '#636363',
        },
    },
    tableContainer: {
        // overflow: 'scroll',
        overflow: 'hidden',
        width: '80vw',
        maxWidth: '700px'
    },
    modalContentContainer: {
        // top: '50%',
        // msTransform: 'translateY(-50%)',
        // transform: 'translateY(10%)',
    },
    modalSubHead: {
        marginTop: '10px !important',
    },
    fileFormField: {
        border: '1px solid #888',
        borderRadius: '3px',
        padding: '5px',
        // width: '25%',
        width: 'min(50%, 200px) !important',
        minWidth: '150px',
    },
    formField: {
        border: '1px solid #888',
        borderRadius: '3px',
        padding: '5px',
        // width: '25%',
        // width: 'min(50%, 200px) !important',
        // minWidth: '150px',
    },
    modalTable: {
        marginTop: '20px !important',
        // width: '500px',
        border: '2px solid #999 !important',
        borderRadius: '5px',
        textAlign: 'left',
        padding: '10px !important',
        paddingBottom: '15px !important',
        display: 'block',
        overflow: 'scroll',
    },
    deleteIcon: {
        fontSize: '20px !important',
        cursor: 'pointer',
        color: 'gray',
        transition: 'all .15s ease-in-out !important',
        '&:hover': {
            color: '#f6862a !important',
            // color: '#f6862a'
            transform: 'scale(1.2)',
        },
    },
    addBtn: {
        // backgroundColor: 'blue !important',
        // width: '100% !important',
    },
    modalEditBtn: {
        // marginTop: '10px !important',
        cursor: 'pointer',
        color: 'white !important',
        backgroundColor: '#f6862a !important',
        padding: '8px !important',
        transition: 'background-color 0.25s',
        '&:hover': {
            backgroundColor: '#e0761f !important',
            // color: '#f6862a'
        },
        '&:disabled': {
            backgroundColor: '#c4c4c4 !important',
            color: '#dbdbdb !important',
            cursor: 'auto',
        },
    },
    day: {
        fontWeight: 'bold !important',
        color: 'white',
        fontSize: '30px !important'
    },
    date: {
        fontWeight: '500 !important',
        color: '#753604',
        fontSize: '16px !important',
    },
    time: {
        fontWeight: '500',
        marginLeft: '7px',
    },
    robots: {
        marginTop: '10px !important',
        fontWeight: 'bold !important',
        color: 'white',
    },
    notes: {
        color: '#80400d',
    },
    
}))

const AddButton = styled(Button)({
    // backgroundColor: 'red !important',
    // width: '100%',
    margin: 'auto',
    // border: '2px solid #f6862a !important',
    backgroundColor: 'white !important',
    padding: '6px 12px !important',
    color: '#f6862a !important',
    // borderColor: '#f6862a !important',
    '&:hover': {
        backgroundColor: '#faf6f2 !important',
        // borderColor: '#0062cc',
        boxShadow: 'none',
    },
})

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80vw',
    height: '60vh',
    maxWidth: '900px',
    padding: '40px',
    backgroundColor: 'white',
    // bgcolor: 'background.paper',
    // border: '2px solid #000',
    borderRadius: '10px',
    overflow: 'scroll',
    // boxShadow: 24,
    // p: 4, 
    // ['@media (max-width:800px)']: { // eslint-disable-line no-useless-computed-key
    //     width: '75%'
    // }
};  

export default JobDetailsSection
