import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const CustomSubheader = ({ content }) => {
    const classes = useStyles();

    return (
        <Typography className={classes.subheader} variant="h2">
            {content}
        </Typography>
    )
}

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            // margin: theme.spacing(1),
        }
    },
    subheader: {
        fontSize: '25px !important',
        textAlign: 'left',
        // '@media (max-width:450px)': {
        //     fontSize: '21px !important',
        // },
        '@media (max-width:600px)': {
            fontSize: 'max(4vw, 20px) !important',
        },
    },
}))

export default CustomSubheader
