import axios from 'axios';

// const API = axios.create({ baseURL: 'http://localhost:5000' });

// API.interceptors.request.use((req) => {
//     if (localStorage.getItem('profile')) {
//         req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile')).token}`;
//     }

//     return req;
// })

let apiObject = {
    googleLogin: function() {
        return axios.get('/user/authenticate')
    },
    httpAuth: function() {
        return axios.get('/user/httpAuth')
    },
    getTokens: function(code) {
        return axios.post('/user/getTokens', code)
    },
    getUserInfo: function(token) {
        return axios.post('/user/getUserInfo', token)
    },
    verifyTokens: function(token) {
        return axios.post('/user/verifyTokens', token)
    },
    logout: function(token) {
        return axios.post('/user/logout', token)
    },
    addJobToSheet: function(jobData) {
        return axios.post('/jobs/addJobToSheet', jobData)
    },
    fetchReports: function(token) {
        return axios.post('/jobs/fetchReports', token)
    },
    findRecord: function(data) {
        return axios.post('/jobs/findRecord', data)
    },
    getReport: function(data) {
        return axios.post('/jobs/getReport', data)
    },
    forceUpdateJob: function(jobName) {
        return axios.post('/jobs/forceUpdateJob', jobName)
    },
    getJobDetails: function(jobDetails) {
        return axios.post('/jobs/getJobDetails', jobDetails)
    },
    editJobDetails: function(jobData) {
        return axios.post('/jobs/editJobDetails', jobData)
    },
    readDustyFile: function(dustyLink) {
        return axios.post('/jobs/readDustyFile', dustyLink)
    },
    uploadLayerDef: function(layerFile) {
        return axios.post('/jobs/uploadLayerDef', layerFile)
    },
    uploadDustyFile: function(dustyFile) {
        return axios.post('/jobs/uploadDustyFile', dustyFile)
    },
    getReportDetails: function(reportName) {
        return axios.post('/jobs/getReportDetails', reportName)
    },
    updateReport: function(reportName) {
        return axios.post('/jobs/updateReport', reportName)
    },
    getDustyFile: function(reportName) {
        return axios.post('/jobs/getDustyFile', reportName)
    },
}

export default apiObject