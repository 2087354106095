import React, { useState, useEffect } from 'react';
import { Typography, Button, CircularProgress, FormControl, TextField, Divider, Select, MenuItem } from '@mui/material';
import { withStyles, makeStyles } from '@mui/styles'
import { Stack, Pagination } from '@mui/material'
import CustomHeader from './CustomHeader';

import { Link } from 'react-router-dom';

import API from '../API/index';
import dateFormat from 'dateformat';
import CustomSubheader from './CustomSubheader';
import SortRoundedIcon from '@mui/icons-material/SortRounded';
import SearchIcon from '@mui/icons-material/Search';
// import { set } from 'mongoose';

const ReportsTable = () => {
    const classes = useStyles()
    const [ sortMethod, setSortMethod ] = useState('Most Recent')
    const [ searchWord, setSearchWord ] = useState('');

    const [ reportsList, setReportsList ] = useState([]);
    const jobsPerPage = 8
    const [ pageCount, setPageCount ] = useState();
    const [ pageIndex, setPageIndex ] = useState(0);

    const [ loadingError, setLoadingError ] = useState(false);
    const [ mobileDisplay, setMobileDisplay ] = useState(window.innerWidth < 600);
    const msInterval = 30000;

    const updateScreenStatus = () => {
        setMobileDisplay(window.innerWidth < 600)
    }

    const handleGetReports = (currentSortMethod, currentSearch) => {
        API.fetchReports({ token: localStorage.getItem('access')})
            .then(res => {
                console.log(res)
                const response = res.data
                // console.log(response)

                // console.log("sortMethod:", sortMethod)
                let sorted;
                if (currentSortMethod == "Most Recent") {
                    // console.log('most recent')
                    sorted = response.sort((a, b) => {
                        let dateA = new Date(a.updated), dateB = new Date(b.updated)
                        return dateB - dateA
                    })
                } else {
                    // console.log('alpha')
                    sorted = response.sort((a, b) => {
                        let dateA = a.name, dateB = b.name
                        return dateB - dateA
                    })
                }
                // console.log('setting reports list', sorted)
                // filter by search term
                // console.log('filter word isssss:', currentSearch)
                let filtered = sorted.filter(report => currentSearch.split(/[ ,]+/).every(v => report.name.toLowerCase().includes(v)))
                // console.log('HAWEOFIAWEJFOAIWEJFAOW', filtered)
                // if (filtered.length !== 0) {
                    setPageCount(Math.ceil(filtered.length/jobsPerPage))
                    setReportsList(filtered)
                // } else {
                //     setPageCount(0)
                //     setReportsList([])
                // }
                console.log('reports fetched')
            })
            .catch(err => {
                console.log('THIS IS THE ERRORRRRRR', err)
                setPageCount(0)
                setReportsList([])
                setLoadingError(true)
            })

    }

    useEffect(() => {

        // initial
        handleGetReports(sortMethod, searchWord)

        const interval = setInterval(() => {
            refreshFunction(sortMethod, searchWord)
        }, msInterval);
        
        return () => clearInterval(interval);

    }, [sortMethod, searchWord])

    useEffect(() => {
        window.addEventListener("resize", updateScreenStatus);
        return () => window.removeEventListener("resize", updateScreenStatus);
    })

    const handleSortChange = (event) => {
        console.log('new value:', event.target.value)
        setPageIndex(0)
        setSortMethod(event.target.value)
        // setTimeout(() => {
            console.log('sortMethod after change', sortMethod)
            handleGetReports(event.target.value, searchWord)
            // hi()
        // }, 2000);
    }

    const handlePageChange = (event, value) => {
        setPageIndex(value - 1)
    }

    const refreshFunction = (curr, searchWord) => {
        // console.log(sortMethod)
        handleGetReports(curr, searchWord)
    }

    const handleUpdateSearch = (val) => {
        console.log(val)
        setSearchWord(val)
        // setTimeout(() => {
            handleGetReports(sortMethod, val)
        // }, 200);
    }

    return (
        <div className={classes.mainContainer}>
            <CustomHeader className={classes.header} content={"QA Reports"}/>
            {/* <Typography className={classes.header} variant='h2'>
                QA Reports
            </Typography> */}
            <div className={classes.subheadContainer}>
                <CustomSubheader className={classes.subheader} content={"Current jobs"} />
                {/* <p>{sortMethod}</p> */}
                {/* <br /> */}
                <div className={classes.filterContainer}>
                    <div className={classes.sortMethodContainer} style={{ marginRight: '40px'}}>
                        <SearchIcon className={classes.sortIcon} />
                        <SpecialTextField 
                            name='searchWord'
                            className={classes.textField}
                            defaultValue={searchWord}
                            onChange={(e) => handleUpdateSearch(e.target.value)}
                            variant="outlined"
                            size="small"
                            />
                    </div>
                    <div className={classes.sortMethodContainer}>
                        <SortRoundedIcon className={classes.sortIcon} />
                        <SpecialFormControl className={classes.dropdown} variant="outlined">
                            <Select
                                defaultValue="Most Recent"
                                onChange={handleSortChange}
                                variant="outlined"
                                >
                                <MenuItem name="Most Recent" value={"Most Recent"}>Most Recent</MenuItem>
                                <MenuItem name="A to Z" value={"A to Z"}>A to Z</MenuItem>
                            </Select>
                        </SpecialFormControl>
                    </div>
                </div>
            </div>
            {/* <Typography className={classes.subheader} variant='h2'>
                Current jobs
            </Typography> */}
            {/* {reportsList.length >= 1 ? ( */}
            {reportsList.length >= 1 && !loadingError ? (
                <>
                    <div className={classes.tableContainer}>
                        {reportsList.slice(jobsPerPage*pageIndex, jobsPerPage*pageIndex+jobsPerPage).map((report, index) => (
                            <div key={report.name}>
                                <Link
                                    to={{
                                        pathname: `/reports/${report.name}`,
                                        state: { reportInfo: report }
                                    }}
                                    className={classes.routerLink}
                                >
                                    <div className={classes.reportItem}>
                                        <p className={classes.reportName}>{report.name.split('.').slice(0, -1).join('.').split('_').join(' ')}</p>
                                        <div className={classes.reportDetail}>
                                            <p className={classes.reportDetail}>
                                                {mobileDisplay ? dateFormat(report.updated, "mmm dS") : dateFormat(report.updated, "mmm dS, yyyy")}
                                            </p>
                                            <p className={classes.reportDetail}>
                                                {dateFormat(report.updated, "h:MM TT")}
                                            </p>
                                            {/* <p>{index}</p> */}
                                        </div>
                                    </div>
                                </Link>
                                {((index+1) % jobsPerPage == 0) || (index == reportsList.length-1) ? (
                                    <></>
                                ): (
                                    <Divider />
                                )}
                            </div>
                        ))}
                    </div>
                    <div className={classes.pageNavContainer}>
                        {/* <Button onClick={() => {setPageIndex(pageIndex - 1)}} disabled={pageIndex<=0}>prev</Button>
                        <Button onClick={() => {setPageIndex(pageIndex + 1)}} disabled={pageIndex+1 >= pageCount}>next</Button> */}
                        <Stack spacing={2} className={classes.pageNav}>
                            {/* <Typography>Page: {pageIndex + 1}</Typography> */}
                            <Pagination count={pageCount} page={pageIndex + 1} onChange={handlePageChange} hidePrevButton hideNextButton/>
                        </Stack>
                    </div>
                </>
            ) : (
                // reportsList.length == 0 ? (
                //     <>
                //         <p>hi</p>
                //     </>
                // ) : (
                //     <>
                //     </>
                // ),
                !loadingError ? (
                    reportsList.length >= 1 ? (
                        <>
                            <CircularProgress style={{marginTop: '25px'}}/>
                        </>

                    ) : (
                        <>
                            <Typography>No reports were found</Typography>
                        </>
                    )
                ) : (
                    <>
                        <p style={{marginTop: '25px'}}>Please refresh the page to see reports.</p>
                    </>
                )
            )}
            {/* <button onClick={() => {console.log(pageIndex)}}>hi</button> */}
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& > *': {
            // margin: theme.spacing(1),
        }
    },
    mainContainer: {
        width: '85vw',
        margin: 'auto',
        // display: 'flex'
        marginBottom: '5vh'
    },
    header: {
        // fontSize: '50px',
        // textAlign: 'left',
    },
    subheadContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '10px',
        '@media (max-width:700px)': {
            display: 'block',
            // justifyContent: 'left'
        },
        '@media (max-width:470px)': {
            display: 'block',
            // justifyContent: 'right'
        },
    },
    filterContainer: {
        display:'flex',
        // flexWrap: 'wrap',
        // justifyContent: 'right',
        '@media (max-width:700px)': {
            marginTop: '10px',
            // justifyContent: 'left'
        },
        '@media (max-width:500px)': {
            // marginTop: '10px',
            // justifyContent: 'left'
            display: 'block',
            justifyContent: 'right',
        },
    },
    sortMethodContainer: {
        // width: 'auto',
        display: 'flex',
        '@media (max-width:500px)': {
            marginTop: '5px',
            // justifyContent: 'left'
            // display: 'block'
        },
        '@media (max-width:470px)': {
            // display: 'block',
            marginLeft: 'auto',
            marginRight: '0',
            // alignItems: 'right',
            justifyContent: 'left',
            // marginTop: '10px',
            // backgroundColor: 'red'
        },
    },
    subheader: {
        // fontSize: '25px',
        // textAlign: 'left',
    },
    sortIcon: {
        marginTop: '5px',
        marginRight: '5px',
        color: '#616161', //gray
        '@media (max-width:445px)': {
            marginTop: '2px',
        },
    },
    textField: {
        width: '185px',
        // justifyContent: 'right',
        '@media (max-width:445px)': {
            width: '200px',
        },
    },
    dropdown: {
        width: '135px',
        // justifyContent: 'right',
        '@media (max-width:445px)': {
            width: '120px',
        },
    },
    tableContainer: {
        // backgroundColor: '#6e6e6e',
        backgroundColor: 'white',
        padding: '15px',
        // marginTop: '20px',
    },
    routerLink: {
        textDecoration: 'none',
        color: 'black',
    },
    reportItem: {
        color: 'black',
        // backgroundColor: '#b8ebff',
        padding: '10px',
        display: 'flex',
        justifyContent: 'space-between',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#d9d9d9'
        },
    },
    reportName: {
        margin: '0',
        // marginTop: '9px',
        verticalAlign: 'middle',
        wordBreak: 'break-word',
        // maxWidth: '180px',
        maxWidth: '65%',
        '@media (max-width:445px)': {
            fontSize: '15px !important',
        },
    },
    reportDetail: {
        margin: '0',
        textAlign: 'right',
        '@media (max-width:445px)': {
            fontSize: '15px !important',
        },
    },
    pageNav: {
        marginTop: '10px'
    },
    timeContainer: {
        // marginLeft: '10px',
        // width: '100px'
    },
    version: {
        position: 'absolute',
        bottom: '5px',
        right: '10px',
        color: '#666666',
        fontSize: '10px !important',
    }
}))

const SpecialTextField = withStyles({
    root: {
        '& .MuiOutlinedInput-input': {
            color: 'black',
            padding: '8px',
            '@media (max-width:450px)': {
                fontSize: '14px',
                padding: '5px',
            },
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'gray',
                // borderWidth: '1px',
                // backgroundColor: 'blue',
            },
            '&:hover fieldset': {
                borderColor: '#f6862a',
                borderWidth: '1px',
            },
            '&.Mui-focused fieldset': {
                // borderColor: 'rgba(246, 134, 42, 1)',
                // borderWidth: '2px',
                backgroundColor: 'transparent',
                borderColor: '#f6862a',
                borderWidth: '2px'
            },
        },
    },
})(TextField);

const SpecialFormControl = withStyles({
    root: {
        '& .MuiOutlinedInput-input': {
            color: 'black',
            padding: '8px',
            '@media (max-width:450px)': {
                fontSize: '14px',
                padding: '5px',
            },
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'gray',
                // borderWidth: '1px',
                // backgroundColor: 'blue',
            },
            '&:hover fieldset': {
                borderColor: '#f6862a',
                borderWidth: '1px',
            },
            '&.Mui-focused fieldset': {
                // borderColor: 'rgba(246, 134, 42, 1)',
                // borderWidth: '2px',
                backgroundColor: 'transparent',
                borderColor: '#f6862a',
            },
        },
    },
})(FormControl);

export default ReportsTable
