import React from 'react';
import ReportsTable from '../components/ReportsTable';

const ReportsPage = () => {
    return (
        <div>
            <ReportsTable />
        </div>
    )
}

export default ReportsPage
